import { put } from 'redux-saga/effects';

import { setTnved, setNationalClassifier, setEnum } from '../actions/enums';
import { API } from '../../api';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';

export function* loadTnvedSaga() {
  try {
    const json = yield API.rest.tnved.list();
    yield put(setTnved(json));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadNationalClassifierSaga() {
  try {
    const json = yield API.rest.national.list();
    yield put(setNationalClassifier(json));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadEnumByNameSaga({ payload }) {
  try {
    const json = yield API.rest.enumeration.getEnum(
      payload.name,
      payload.sortLanguage
    );
    yield put(setEnum({ ...json }));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadTypicalCommentsSaga() {
  try {
    const json = yield API.rest.typicalComments.list();
    yield put(setEnum({ name: 'typicalComments', values: [...json] }));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadProductGroupsEnumSaga() {
  try {
    const json = yield API.rest.productGroups.list();
    const values = json.map(({ code, id, name }) => ({ code, id, name }));
    yield put(setEnum({ name: 'productGroupsEnum', values }));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadProductCategoriesEnumSaga() {
  try {
    const json = yield API.rest.category.list({ limit: 1000 });
    const values = json.map(({ code, name }) => ({ code, name }));
    yield put(setEnum({ name: 'productCategoriesEnum', values }));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadCategoriesListSaga({ payload }) {
  try {
    const json = yield API.rest.productGroups.getListByProductGroup(payload);
    yield put(
      setEnum({
        name: 'categories',
        values: [...json],
      })
    );
    const values = json.map(({ code, name }) => ({ code, name }));
    yield put(setEnum({ name: 'productCategoriesEnum', values }));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadTnvedByCategorySaga({ payload }) {
  try {
    const json = yield API.rest.category.getTnvedByCategory(payload);
    yield put(
      setEnum({
        name: 'tnved',
        values: [...json.tnved_codes],
      })
    );
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadProductGroupsByCategorySaga({ payload }) {
  try {
    const json = yield API.rest.productGroups.getTnvedByCategory(payload);
    yield put(
      setEnum({
        name: 'tnved',
        values: [...json.tnved_codes],
      })
    );
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadPackagesCategorySaga({ payload }) {
  try {
    const json = yield API.rest.productGroups.getPackagesProductGroup(payload);
    // const list = json.tnved_codes || [];
    yield put(
      setEnum({
        name: 'tnved',
        values: [...json],
      })
    );
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loadModeratorsSaga() {
  try {
    const json = yield API.rest.moderator.suggest({ size: 100 });
    yield put(
      setEnum({
        name: 'moderators',
        values: [...json],
      })
    );
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}
