import { lazy } from 'react';

/**
 *
 * Asynchronously loads the component for ProductGroupDetails
 *
 */
export const ProductGroupDetails = lazy(() =>
  import('./index').then((module) => ({ default: module.ProductGroupDetails }))
);
