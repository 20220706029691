import { SvgIcon, SvgIconProps } from '@crpt/material';
import React from 'react';

export const LogoIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="4" fill="#F6F42E" />
    <path
      clipRule="evenodd"
      d="M6 7C6 6.44772 6.44772 6 7 6H9.5V4H7C5.34315 4 4 5.34315 4 7V9.5H6V7ZM14.5 6H17C17.5523 6 18 6.44772 18 7V9.5H20V7C20 5.34315 18.6569 4 17 4H14.5V6ZM6 17V14.5H4V17C4 18.6569 5.34315 20 7 20H9.5V18H7C6.44772 18 6 17.5523 6 17ZM18 14.5V17C18 17.5523 17.5523 18 17 18H14.5V20H17C18.6569 20 20 18.6569 20 17V14.5H18ZM11.3846 15.6962L16.7179 10.1962L15.2821 8.8039L10.6985 13.5307L8.74741 11.3357L7.25259 12.6644L9.91925 15.6644L10.6348 16.4694L11.3846 15.6962Z"
      fill="#555D67"
      fillRule="evenodd"
    />
  </SvgIcon>
);
