import { Types, types } from '../types';

import {
  ParticipantSource,
  ParticipantStatus,
  ParticipantStatusExt,
} from './participants';
import {
  ProductGroupModeration,
  PackageType,
  ProductGroupStatusCode,
} from './productGroups';

import { Name, PageState, PaginationData } from '.';

type ParticipantData = {
  inn: string;
  name: Name;
  status: ParticipantStatus;
  source: ParticipantSource;
  status_ext: ParticipantStatusExt | null;
  gs1_active: boolean;
};

type UserData = {
  id: number;
  full_name: string;
};

type ProductGroupData = {
  id: number;
  code: string;
  name: Name;
  status: {
    code: ProductGroupStatusCode;
    name: Name;
  };
  moderation: ProductGroupModeration;
  packages: PackageType[];
};

type TaskStatus = 'CREATED' | 'IN_PROGRESS' | 'DONE_ACCEPTED' | 'DONE_REJECTED';

type ProductData = {
  id: string;
  name: Name;
  category_name: Name | null;
  product_group: ProductGroupData;
};

export type TaskData = {
  id: number;
  participant: ParticipantData;
  gtin: string;
  status: TaskStatus;
  assigned_user: UserData | null;
  date_create: string;
  date_finish: string | null;
  product: ProductData;
};

type TaskListResponseData = {
  content: TaskData[];
  total: number;
};

export type TasksState = PageState<TaskData, TaskData>;

const PAGINATION_DEFAULT: PaginationData = {
  limit: 100,
  offset: 0,
  page: 0,
};

const initialState: TasksState = {
  list: [],
  item: undefined,
  filter: undefined,
  sorting: {},
  isLastPage: false,
  pagination: PAGINATION_DEFAULT,
  total: undefined,
};

export type TasksActionType = Types[keyof Pick<
  Types,
  | 'SET_TASKS_LIST'
  | 'SET_TASKS'
  | 'SET_TASK_ITEM'
  | 'CLEAR_TASKS_LIST'
  | 'CLEAR_TASKS'
  | 'GET_TASKS'
  | 'GET_TASK_ITEM'
  | 'CHANGE_TASK_STATUS'
  | 'ADD_COMMENTS'
  | 'DELETE_COMMENT'
  | 'GET_COMMENTS'
  | 'SET_COMMENTS'
  | 'ASSIGN_TASK'
  | 'SET_ASSIGNED_TASK_WITH_ERROR'
  | 'SET_TASK_STATUS_CHANGED'
  | 'GET_REPORT'
>];

type TasksAction = {
  type: TasksActionType;
  payload: unknown;
};

export const reducer = (
  state: TasksState = initialState,
  action: TasksAction
): TasksState => {
  switch (action.type) {
    case types.SET_TASKS_LIST: {
      const {
        content: data,
        total,
        limit,
      } = action.payload as TaskListResponseData;
      return {
        ...state,
        isLastPage: data.length < limit,
        list: [...state.list, ...data],
        total,
      };
    }
    case types.SET_TASKS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_TASK_ITEM:
      return { ...state, item: action.payload as TaskData };
    case types.CLEAR_TASKS_LIST:
      return {
        ...state,
        list: [],
      };
    case types.SET_TASK_STATUS_CHANGED: {
      const data = action.payload as TaskData;
      const list = [...state.list].map((item) =>
        item.id === data.id ? { ...item, status: data.status } : item
      );
      return {
        ...state,
        list: [...list],
        item: {
          ...state.item,
          status: data.status,
        } as TaskData,
      };
    }
    case types.CLEAR_TASKS:
      return { ...initialState };
    default:
      return state;
  }
};
