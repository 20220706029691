import React from 'react';

import { AnyMaskedOptions } from 'imask';
import { IMaskMixin } from 'react-imask';

import { TextField, TextFieldProps } from '@crpt/material/src';

const TextFieldMasked = IMaskMixin<
  AnyMaskedOptions,
  false,
  string,
  HTMLInputElement,
  TextFieldProps
>(({ inputRef, ...props }) => <TextField {...props} inputRef={inputRef} />);

export const InputForDatePicker = React.forwardRef<
  HTMLInputElement,
  TextFieldProps
>(({ ref: _, ...props }, ref) => {
  return <TextFieldMasked inputRef={ref} mask={Date} {...props} />;
});
