import { default as fp } from 'lodash/fp';

import { ActionType } from '../store/types';

export const createSyncAction = (type) => (payload) => ({ type, ...payload });

export const update_ =
  (list) =>
  (...args) => {
    const items = fp.flatten(args);
    return fp.map((i) => fp.find({ id: i.id }, items) || i)(list);
  };

export const add_ =
  (list) =>
  (...args) => {
    const items = fp.flatten(args);
    return fp.pipe(
      update_(list),
      fp.concat(fp, fp.differenceBy('id', items, list))
    )(list);
  };

export const remove_ = (list) => (id) => fp.reject({ id }, list);

export type Action<T> = {
  type: T;
  payload: unknown;
};

export const createAction =
  <T extends ActionType>(type: T) =>
  (payload?: unknown): Action<T> => ({
    type,
    payload: payload,
  });
