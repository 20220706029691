/* eslint-disable */
import Cookies from 'js-cookie';

export const b64DecodeUnicode = (str) => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(base64), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

export function saveToken(token) {
  const data = token.split('.')[1];
  const decoded = b64DecodeUnicode(data);
  const { exp } = JSON.parse(decoded);
  const expires = new Date(exp * 1000);
  const domain = getCurrentDomain();
  const path = '/';

  const options = {
    expires,
    domain,
    path,
  };

  if (expires && new Date(expires) < new Date()) {
    deleteAllCookies();
  } else {
    const slices = getTokenSlices(token);
    const count = slices.length;
    Cookies.set('count', count, options);

    slices.forEach((tokenPart, i) => {
      Cookies.set(`tokenPart${i + 1}`, tokenPart, options);
    });
  }
}

export function getTokenSlices(token) {
  let i;
  let j;
  let splitted = token.split('');
  let results = [];
  let chunk = 4000;
  for (i = 0, j = splitted.length; i < j; i += chunk) {
    results.push(splitted.slice(i, i + chunk));
  }
  return results.map((v) => v.join(''));
}

export function saveCertSerial(value) {
  deleteCookie('certSerial', getCurrentDomain());
  Cookies.set('certSerial', value, {
    domain: getCurrentDomain(),
  });
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(cookieName, value) {
  deleteCookie(cookieName, getCurrentDomain());
  Cookies.set(cookieName, value, {
    domain: getCurrentDomain(),
  });
}

function deleteCookie(name, domain) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
}

export function getCurrentDomain() {
  return `${window.location.hostname.split('.').slice(-2).join('.')}`;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${getCurrentDomain()}`;
  }

  localStorage.clear();
}
