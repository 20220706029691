import React from 'react';
import { Box, BoxProps } from '@crpt/material';

interface MenuItemProps extends BoxProps {
  label?: string;
  icon?: React.ReactNode;
}

export const MainMenuPanel = React.forwardRef<typeof Box, MenuItemProps>(
  ({ label, icon, children, ...boxProps }, ref) => {
    return (
      <Box ref={ref} className="AppMainMenu-Panel">
        {icon && <Box className="AppMainMenu-ItemIcon">{icon}</Box>}
        {label && <Box className="AppMainMenu-ItemLabel">{label}</Box>}
        <Box className="AppMainMenu-PanelContent" {...boxProps}>{children}</Box>
      </Box>
    );
  }
);
