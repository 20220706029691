import { put } from 'redux-saga/effects';
import i18n from 'i18next';
import { push } from 'react-router-redux';
import { omit } from 'lodash-es';

import { setIsLoading } from '../actions/common';
import {
  setItem,
  setList,
  setParticipantProducts,
  clear,
} from '../actions/participants';
import { API } from '../../api';
import { routes } from '../../routes';
import { isIncorrectInputError } from '../../helpers';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';

export function* loadParticipantsListSaga({ payload }) {
  const filters = omit(payload.filter, 'status');
  const query = {
    ...payload.pagination,
    ...payload.sorting,
    status: payload.filter?.status,
  };
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.list(filters, query);
    yield put(setList([...json]));
    yield put(setIsLoading(false));
  } catch (err) {
    if (isIncorrectInputError(err)) {
      yield put(clear());
    } else {
      yield put(
        push(
          routes.serverError('', { code: err?.response?.data?.status || 500 })
        )
      );
    }
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadParticipantsItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.item(action.payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* blockParticipantsSaga(action) {
  try {
    const { inn, isList, filter, pagination } = action.payload;
    yield put(setIsLoading(true));
    yield API.rest.participants.block(inn);
    if (isList) {
      const json = yield API.rest.participants.list(filter, pagination);
      yield put(setList([...json]));
    } else {
      const json2 = yield API.rest.participants.item(inn);
      yield put(setItem({ ...json2 }));
    }
    yield put(setIsLoading(false));
    yield Toast.showSuccess({
      content: i18n.t('УОТ успешно заблокирован!'),
    });
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* updateParticipantsSaga(action) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.participants.changeNames(
      action.payload.inn,
      action.payload.body
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* getParticipantProductsSaga({ payload }) {
  try {
    const json = yield API.rest.product.getParticipantProducts({
      status: 'PUBLISHED',
      ...(payload ? { inn: payload } : {}),
    });
    yield put(setParticipantProducts(json));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}
