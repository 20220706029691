import { SvgIcon } from '@mui/material';
import React from 'react';

interface CloseButtonProps {
  className?: string;
  onClick?: () => void;
}

export const CloseLargeIcon = ({ onClick, className }: CloseButtonProps) => (
  <SvgIcon
    className={className}
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28033 3.21967C3.98744 2.92678 3.51256 2.92678 3.21967 3.21967C2.92678 3.51256 2.92678 3.98744 3.21967 4.28033L11.0893 12.15L3.21967 20.0197C2.92678 20.3126 2.92678 20.7874 3.21967 21.0803C3.51256 21.3732 3.98744 21.3732 4.28033 21.0803L12.15 13.2107L20.0197 21.0803C20.3126 21.3732 20.7874 21.3732 21.0803 21.0803C21.3732 20.7874 21.3732 20.3126 21.0803 20.0197L13.2107 12.15L21.0803 4.28033C21.3732 3.98744 21.3732 3.51256 21.0803 3.21967C20.7874 2.92678 20.3126 2.92678 20.0197 3.21967L12.15 11.0893L4.28033 3.21967Z"
    />
  </SvgIcon>
);
