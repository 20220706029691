import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { TextField } from '@crpt/material';

import { FilterEditor } from './FilterEditor';
import type {
  FilterRenderProps,
  FilterValidationHandler,
} from './TableFilter.types';

interface TextInputFilterOptions {
  title?: React.ReactNode;
  helperText?: string;
  label?: string;
  placeholder?: string;
  validator?: FilterValidationHandler<string | string[]>;
}

export function makeGtinFilter(filterOptions?: TextInputFilterOptions) {
  const { title, validator, helperText, label, placeholder } =
    filterOptions || {};
  return memo((props: FilterRenderProps<string | string[]>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;
    const { error } = useValidator(validator);
    const { t } = useTranslation();

    const value = useMemo(() => {
      return Array.isArray(filterValue)
        ? filterValue
        : filterValue?.replace(/[^\d]+/g, '');
    }, [filterValue]);

    const text = helperText || t('Неверный формат GTIN');
    const hasError = Boolean(error) || (value && String(value)?.length !== 14);

    return (
      <FilterEditor column={column} error={hasError ? text : ''} title={title}>
        <TextField
          error={hasError}
          label={label}
          name={column.id}
          placeholder={placeholder}
          value={value ?? ''}
          onBlur={executeValidation}
          onChange={setFilterValue}
        />
      </FilterEditor>
    );
  });
}
