import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { isInArray } from '../../utils/typescript.utils';

import { reducer as settingsReducer, SettingsState } from './settings';
import {
  ParticipantsState,
  reducer as participantsReducer,
} from './participants';
import {
  FiduciaryParticipantsState,
  reducer as fiduciaryParticipantsReducer,
} from './fiduciary-participants';
import { PrefixesState, reducer as prefixesReducer } from './prefixes';
import { reducer as enumsReducer, EnumsState } from './enums';
import { ProductsState, reducer as productsReducer } from './products';
import {
  TrustedParticipantsState,
  reducer as trustedParticipantsReducer,
} from './trusted-participants';
import authReducer from './auth';
import {
  ProductGroupsState,
  reducer as productGroupsReducer,
} from './productGroups';
import { productGroupDetailsReducer } from './productGroupDetails';
import { reducer as currentUserReducer, CurrentUserState } from './currentUser';
import { TasksState, reducer as tasksReducer } from './tasks';

export type Language = 'en' | 'ru' | 'uz' | 'hy' | 'kz';
export type Country = 'hy' | 'am' | 'az' | 'kz' | 'uz';
export type CountryCode = 'HY' | 'AM' | 'AZ' | 'KZ' | 'UZ';

const countries: readonly Country[] = ['hy', 'am', 'az', 'kz', 'uz'] as const;

export const toCountry = (code: string): code is Country =>
  isInArray<Country>(countries)(code as Country);

export type Name<T = string> = Record<Language, T>;

export type TranslatedString = {
  name: Name;
  value: string;
};

export type PaginationData = { limit: number; page: number; offset: number };

type possibleTrustedParticipant = {
  inn: string;
  name: Name;
};

export type PageState<T extends object = {}, I = unknown> = {
  list: T[];
  item?: I;
  possibleTrustedParticipants: possibleTrustedParticipant[];
  filter: unknown;
  sorting: unknown;
  isLastPage: boolean;
  pagination: PaginationData;
  total?: number;
};

export type PageQueryParams<
  T extends { filter: unknown; sorting: unknown; pagination: PaginationData }
> = Pick<T, 'filter' | 'pagination' | 'sorting'>;

export type RootState = {
  enums: EnumsState;
  participants: ParticipantsState;
  fiduciaryParticipants: FiduciaryParticipantsState;
  prefixes: PrefixesState;
  products: ProductsState;
  productGroups: ProductGroupsState;
  settings: SettingsState;
  tasks: TasksState;
  currentUser: CurrentUserState;
  trustedParticipants: TrustedParticipantsState;
};

export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    settings: settingsReducer,
    enums: enumsReducer,
    participants: participantsReducer,
    fiduciaryParticipants: fiduciaryParticipantsReducer,
    prefixes: prefixesReducer,
    products: productsReducer,
    productGroups: productGroupsReducer,
    productGroupDetails: productGroupDetailsReducer,
    currentUser: currentUserReducer,
    tasks: tasksReducer,
    trustedParticipants: trustedParticipantsReducer,
  });
