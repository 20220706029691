import React from 'react';

import cn from 'classnames';

import { Chip, ChipProps } from '@crpt/material';

import css from './index.module.scss';

export type ChipStatusColor = React.ComponentProps<typeof ChipStatus>['color'];

export const ChipStatus = React.memo<ChipProps>(({ className, ...props }) => (
  <Chip className={cn(className, css.container)} {...props} />
));
