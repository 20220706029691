import React, { useEffect, FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultOptions } from 'date-fns';
import { enUS as en, ru, uz, kk as kz, hy } from 'date-fns/locale';

import { ListItemText, MenuItem, MenuList } from '@crpt/material';
import { MainMenuItem } from '@crpt/header';
import { setDefaultLocale, registerLocale } from 'react-datepicker';

import { saveLanguage } from '../../store/actions/common';
import { getCookie, setCookie } from '../../utils/cookieHelper';
import { useEvent } from '../../utils/react.hooks';
import { useOverriddenTheme } from '../../hooks/theme';
import { RootState, Language } from '../../store/reducers';

import styles from './languageSwitcher.module.scss';

registerLocale('hy', hy);
registerLocale('ru', ru);
registerLocale('en', en);
registerLocale('uz', uz);
registerLocale('kz', kz);

const LOCALES: Record<Language, Locale> = {
  en,
  ru,
  uz,
  kz,
  hy,
};

export const LanguageSwitcher: FC = () => {
  const [_, i18n] = useTranslation();
  const dispatch = useDispatch();
  const languages = useSelector((state: RootState) => state.enums.language);
  const language = useSelector((state: RootState) => state.settings.language);
  const countryCode = useSelector(
    (state: RootState) => state.settings.common.countryCode
  );
  const theme = useOverriddenTheme(countryCode);

  useEffect(() => {
    const language = (getCookie('language') || 'ru') as Language;
    changeLanguage(language);
  }, []);

  const changeLanguage = useEvent((language: Language) => {
    const langLowerCase = language.toLowerCase();
    setDefaultOptions({ locale: LOCALES[language] });
    i18n.changeLanguage(language);
    dispatch(saveLanguage(language));
    setCookie('language', langLowerCase);
    localStorage.setItem('language', language);
    setDefaultLocale(langLowerCase);
  });

  const onLangSelect = useEvent((language: Language, close: () => void) => {
    changeLanguage(language);
    close();
  });

  return (
    <div className={styles.languageSwitcher}>
      <MainMenuItem dropdown label={language}>
        {(close) => (
          <MenuList>
            {languages.map(({ code }) => (
              <MenuItem
                key={code}
                selected={code === i18n.language}
                theme={theme}
                onClick={() => onLangSelect(code, close)}
              >
                <ListItemText>{code.toLocaleUpperCase()}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </MainMenuItem>
    </div>
  );
};
