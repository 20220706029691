import {
  createTheme,
  Stack,
  StackProps,
  styled,
  ThemeProvider,
} from '@mui/material';
import React from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f6f42e',
    },
    text: {
      primary: '#63666a',
      secondary: '#b8b8b8',
    },
  },
  typography: {
    fontFamily: "'Segoe UI', sans-serif",
  },
});

export interface HeaderProps extends StackProps {
  variant?: 'contained' | 'outlined';
}

export const Header = styled(
  ({ children, ...rest }: HeaderProps) => (
    <ThemeProvider theme={theme}>
      <Stack {...rest}>{children}</Stack>
    </ThemeProvider>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'variant',
  }
)(({ variant = 'contained' }) => ({
  width: '100%',

  ...(variant === 'contained' && {
    background: '#63666a',
    color: theme.palette.common.white,
  }),

  ...(variant === 'outlined' && {
    boxShadow: '0 -1px 0 0 #f2f2f2 inset',
  }),
}));

Header.defaultProps = {
  direction: 'row',
  gap: 2,
};
