import { Box, defaultTheme, Drawer, styled } from '@crpt/material';
import React, { useCallback, useMemo, useState } from 'react';
import { ArrowDownIcon } from './components/ArrowDownIcon';
import { ProductGroupListItem } from './ProductGroupSelector.types';
import { CloseLargeIcon } from './components/CloseLargeIcon';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

const ProductGroupSelectorRoot = styled(ButtonBase)<ButtonBaseProps>(
  ({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.grey['10'],
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 8,
    height: 56,
    gap: 8,
    '&:after': {
      content: '" "',
      position: 'absolute',
      display: 'block',
      left: '100%',
      top: 0,
      width: 0,
      height: 0,
      borderTop: '28px solid transparent',
      borderBottom: '28px solid transparent',
      borderLeft: `16px solid ${theme.palette.grey['10']}`,
    },
  })
);

ProductGroupSelectorRoot.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
};

const ProductGroupSidebarRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['05'],
  minWidth: 500,
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',

  '.SidebarHeader': {
    margin: 24,
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    '&_Title': {
      ...theme.typography.h3,
      color: theme.palette.grey['100'],
      flex: '1 1 auto',
    },
    '&_CloseButton': {
      flex: '0 0 auto',
      color: theme.palette.blue['70'],
      cursor: 'pointer',
    },
  },
  '.SidebarList': {
    '&_Item': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 12,
      padding: '8px 24px',
      '&:hover .SidebarList_ItemIcon': {
        borderColor: theme.palette.grey['50'],
      },
      '&:active .SidebarList_ItemIcon': {
        backgroundColor: theme.palette.grey['40'],
        borderColor: theme.palette.grey['50'],
      },
    },
    '&_ItemIcon': {
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: theme.palette.grey['30'],
      color: theme.palette.grey['70'],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid',
      borderColor: theme.palette.grey['30'],
    },
    '&_ItemCaption': {
      ...theme.typography.p1,
      color: theme.palette.grey['100'],
    },
  },
}));

ProductGroupSidebarRoot.defaultProps = {
  theme: defaultTheme,
};

interface ProductGroupSelectorProps {
  title: string;
  list: ProductGroupListItem[];
  value: string;
  onChange?: (value: string) => void;
}

export function ProductGroupSelector({
  title,
  list,
  value,
  onChange,
}: ProductGroupSelectorProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);

  const current = useMemo(
    () => list.find((item) => item.value === value),
    [value]
  );

  const onSelect = useCallback(
    (newValue) => {
      if (value !== newValue && onChange) {
        onChange(newValue);
      }
      setIsOpen(false);
    },
    [onChange, value]
  );

  return (
    <>
      <ProductGroupSelectorRoot onClick={onOpen}>
        {current?.icon}
        <ArrowDownIcon />
      </ProductGroupSelectorRoot>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={onClose}
        PaperProps={{ style: { width: 'auto' } }}
      >
        <ProductGroupSidebarRoot>
          <Box className="SidebarHeader">
            <Box className="SidebarHeader_Title">{title}</Box>
            <Box className="SidebarHeader_CloseButton" onClick={onClose}>
              <CloseLargeIcon />
            </Box>
          </Box>
          <Box className="SidebarList">
            {list.map(({ name, value: itemValue, icon, ...menuItemProps }) => (
              <ButtonBase
                {...menuItemProps}
                className="SidebarList_Item"
                key={itemValue}
                onClick={() => onSelect(itemValue)}
                disableRipple
              >
                <Box className="SidebarList_ItemIcon">{icon}</Box>
                <Box className="SidebarList_ItemCaption">{name}</Box>
              </ButtonBase>
            ))}
          </Box>
        </ProductGroupSidebarRoot>
      </Drawer>
    </>
  );
}

ProductGroupSelector.defaultProps = {
  title: 'Выберите товарную группу',
};
