import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { setFavicon, setPageTitle } from '../../helpers';

import './index.scss';
import { ThemeProvider } from '@mui/material';

import { useOverriddenTheme } from '../../hooks/theme';

export const Theme = ({ children }) => {
  const { countryCode } = useSelector((state) => state.settings.common);

  useEffect(() => {
    setFavicon(countryCode);
    setPageTitle(countryCode);
    document.getElementById('body').className =
      'theme-' + countryCode.toLowerCase();
  }, [countryCode]);

  const theme = useOverriddenTheme(countryCode);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
