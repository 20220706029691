import { Types, types } from '../types';

import { PAGINATION_DEFAULT } from './constants';

import { PageState } from '.';

export type TrustedParticipantsData = {
  id: string;
  inn: string;
  associate_id?: null | string;
  has_associate?: boolean;
};

export type TrustedParticipantsState = PageState<TrustedParticipantsData> & {
  trustedParticipantsByInn: unknown[];
  trustedParticipantsByName: unknown[];
  trustedParticipants: unknown[];
  participants: unknown;
};

export type TrustedParticipantsActionType = Types[keyof Pick<
  Types,
  | 'SET_TRUSTED_PARTICIPANTS_BY_INN'
  | 'SET_TRUSTED_PARTICIPANTS_BY_NAME'
  | 'SET_TRUSTED_PARTICIPANTS'
  | 'TRUSTED_PARTICIPANTS_SET_LIST'
  | 'TRUSTED_PARTICIPANTS_CLEAR'
>];

type TrustedParticipantsAction = {
  type: TrustedParticipantsActionType;
  payload: unknown;
};

const initialState: TrustedParticipantsState = {
  trustedParticipantsByInn: [],
  trustedParticipantsByName: [],
  trustedParticipants: [],
  participants: {
    list: [],
    filter: undefined,
    sorting: {},
    isLastPage: false,
    pagination: PAGINATION_DEFAULT,
  },
};

export const reducer = (
  state: TrustedParticipantsState = initialState,
  action: TrustedParticipantsAction
) => {
  switch (action.type) {
    case types.SET_TRUSTED_PARTICIPANTS_BY_INN:
      return {
        ...state,
        trustedParticipantsByInn: action.payload,
      };
    case types.SET_TRUSTED_PARTICIPANTS_BY_NAME:
      return {
        ...state,
        trustedParticipantsByName: action.payload,
      };
    case types.SET_TRUSTED_PARTICIPANTS:
      return {
        ...state,
        trustedParticipants: action.payload,
      };
    case types.TRUSTED_PARTICIPANTS_SET_LIST: {
      const data = action.payload;
      return {
        ...state,
        participants: {
          ...state.participants,
          isLastPage: data.length < state.participants.pagination.limit,
          list: [...state.participants.list, ...data],
        },
      };
    }
    case types.TRUSTED_PARTICIPANTS_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
