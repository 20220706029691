import { ListWrapperTab } from '../../components/wrappers/list-wrapper';
import { Translate } from '../../components/translate';

import { Tab } from './index';

export const ProductFilterOrder = [
  'gtinColumn',
  'gtin',
  'techGtin',
  'productName',
  'tnved',
  'inn',
  'productGroup',
  'productCategory',
  'packageType',
  'updated_at',
  'languageComplete',
  'accessType',
];

export const sides = {
  front_side: 'Лицевая',
  back_side: 'Обратная',
  left_side: 'Левая',
  right_side: 'Правая',
  upper_side: 'Верхняя',
  down_side: 'Нижняя',
  other: 'Другие',
};

export const photoCodes = [
  'back_side',
  'front_side',
  'down_side',
  'upper_side',
  'right_side',
  'left_side',
  'other',
];

export const MAX_FILE_SIZE_MB = parseInt(window.env.RMT_MAX_FILE_SIZE, 10);
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export const STEPS = [
  {
    label: 'Базовая информация',
    step: 0,
  },
  {
    label: 'Атрибуты товара',
    step: 1,
  },
  {
    label: 'Фото и файлы',
    step: 2,
  },
];

export const TABS_ALL: ListWrapperTab<Tab>[] = [
  {
    label: Translate('Черновики'),
    value: 'DRAFT',
  },
  {
    label: Translate('На модерации'),
    value: 'ON_MODERATION',
  },
  {
    label: Translate('Опубликованные'),
    value: 'PUBLISHED',
  },
  {
    label: Translate('Заблокированные'),
    value: 'BLOCKED',
  },
];

export const accessType = [
  {
    code: 'all',
    translations: { en: 'All', ru: 'Все', uz: 'Hammasi' },
  },
  {
    code: 'owned',
    translations: {
      en: 'Only your own CTP',
      ru: 'Только своего УОТ',
      uz: "Faqat o'zingizniki MAI",
    },
  },
  {
    code: 'associate',
    translations: {
      en: 'Available products from other CTP',
      ru: 'Доступные товары других заявителей',
      uz: 'Boshqa MAI mavjud tovarlar',
    },
  },
  {
    code: 'other',
    translations: {
      en: 'The goods of other participants',
      ru: 'Прочие товары других заявителей',
      uz: 'Boshqa ishtirokchilarning mahsulotlari',
    },
  },
];
