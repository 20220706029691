import { buttonClasses, menuItemClasses } from '@mui/material';

export const getComponents = (palette) => {
  return {
    MuiDrawer: {
      styleOverrides: {
        root: {
          [`.FilterForm .${buttonClasses.containedPrimary}`]: {
            color: palette.text.contrastText,
            backgroundColor: palette.yellow['40'],
            border: `1px solid ${palette.yellow['40']}`,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          [`.ConfirmForm-Footer .${buttonClasses.containedPrimary}`]: {
            color: palette.text.contrastText,
            backgroundColor: palette.yellow['40'],
            border: `1px solid ${palette.yellow['40']}`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [`&.${menuItemClasses.selected}`]: {
            color: palette.text.contrastText,
            background: palette.yellow['40'] + '!important',
          },
        },
      },
    },
  };
};
