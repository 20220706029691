import { createAction } from '../../helpers/redux';
import { PrefixesActionType } from '../reducers/prefixes';
import { types } from '../types';

const createPrefixesAction = createAction<PrefixesActionType>;

export const set = createPrefixesAction(types.SET_PREFIXES);
export const getList = createPrefixesAction(types.PREFIXES_GET_LIST);
export const getListMy = createPrefixesAction(types.PREFIXES_GET_LIST_MY);
export const setList = createPrefixesAction(types.PREFIXES_SET_LIST);
export const clear = createPrefixesAction(types.CLEAR_PREFIXES);
export const block = createPrefixesAction(types.BLOCK_PREFIXES);
export const remove = createPrefixesAction(types.DELETE_PREFIXES);
export const create = createPrefixesAction(types.CREATE_PREFIXES);
export const setCreated = createPrefixesAction(types.PREFIX_CREATED);
