/* eslint-disable */
import React, { useEffect, useState } from 'react';
import fp from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { check } from '../../store/actions/auth';
import { Spinner } from '../../components/spinner';
// import LoginPage from './LoginPage';

const Auth = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const dsp = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (!language) {
      localStorage.setItem('language', 'ru');
      i18n.changeLanguage('ru');
    } else {
      i18n.changeLanguage(language.toLowerCase());
    }
    dsp(
      check({
        onFinish: (isNeedLogin) => {
          if (!isNeedLogin) {
          }
          setIsLoading(false);
        },
      })
    );
  }, []);
  // return <>{children}</>;
  return (
    // eslint-disable-next-line no-nested-ternary
    fp.isEmpty(auth) || isLoading ? (
      <div className="spinnerWrap">
        <Spinner />
      </div>
    ) : auth.isNotAuth ? (
      <div />
    ) : (
      children
    )
  );
};

export default Auth;
