import React from 'react';
import { Box, defaultTheme, styled } from '@crpt/material';

const DropDownMarkerRoot = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  padding: '4px 0',
  marginLeft: 4,
  display: 'inline-flex',
  justifyContent: 'center',
  boxSizing: 'border-box',
  transition: theme.transitions.create(['transform']),
  '&:after': {
    content: '""',
    display: 'block',
    transform: 'rotate(-45deg)',
    width: 0,
    height: 0,
    borderWidth: 3,
    borderStyle: 'solid',
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
  },
  '&.isOpen': {
    transform: 'rotate(-180deg)',
  },
}));

DropDownMarkerRoot.defaultProps = {
  theme: defaultTheme,
};

interface DropDownIconProps {
  open?: boolean;
  color?: string;
}

export function DropDownMarker({ open }: DropDownIconProps) {
  return <DropDownMarkerRoot className={open ? 'isOpen' : ''} color="inherit" />;
}
