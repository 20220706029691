/* eslint-disable */
import React from 'react';

import moment from 'moment';

import { Link, Stack } from '@crpt/material/src';
import {
  makeSelectFilter,
  makeDateRangeFilter,
} from '@crpt/table';

import { makeAsyncLookupFilter } from '../../components/wrappers/table-filter-wrapper/components/AsyncLookupFilter';
import { Button } from '../../components/crpt-ui-wrappers/button';
import { Translate } from '../../components/translate';
import { Column } from '../../components/table';
import { TaskData } from '../../store/reducers/tasks';
import { Cell } from '../../components/table/cells/cell';
import { ApplicationConfig } from '../../components/application-config/application-config';
import { API } from '../../api';
import { ISelectOptions } from '../../types/filters';
import { makeMultiSelectFilter } from '../../components/multi-select-filter';
import { InputForDatePicker } from '../../components/input-for-datepicker';
import { useOverriddenTheme } from '../../hooks/theme';
import { Language } from '../../store/reducers';

import { ChipStatusTask } from './components/chip-status-task';

import { Tab } from '.';

import css from './index.module.scss';
import themeButton from './button.module.scss';
import { makeGtinFilter } from "../../components/wrappers/table-filter-wrapper/components/GtinFilter";

const statusOptions: ISelectOptions = [
  { value: 'CREATED', label: Translate('Зарегистрировано') },
  { value: 'IN_PROGRESS', label: Translate('В обработке') },
  { value: 'DONE_ACCEPTED', label: Translate('Выполнено') },
  { value: 'DONE_REJECTED', label: Translate('Отклонено') },
];

const moderationStatusOptions: ISelectOptions = [
  { value: 'CREATED', label: Translate('Зарегистрировано') },
  { value: 'IN_PROGRESS', label: Translate('В обработке') },
];

export const PACKAGE_TYPES = {
  TRADE_UNIT: 'Потребительская',
  INNER_PACK: 'Групповая',
  SET: 'Набор',
}

export type TaskTableData = TaskData & {
  dateCreate: string;
  dateFinish: string;
  productName: string;
  participantName: string;
  productGroupName: string;
  productCategoryName?: string;
  assignedUserName?: string;
  packageType?: string;
  dateTimeFinish?: string;
};

export const statuses = {
  CREATED: 'Зарегистрировано',
  IN_PROGRESS: 'В обработке',
  DONE_ACCEPTED: 'Выполнено',
  DONE_REJECTED: 'Отклонено',
};

export const assignedUserId = 'assignedUserId';

export const filterNames = {
  gtin: 'GTIN',
  productGroupCode: 'Товарная группа',
  status: 'Статус',
  category: 'Категория товара',
  packageType: 'Тип упаковки',
  dateCreateFrom: 'Дата создания',
  [assignedUserId]: 'Исполнитель',
  participantInn: 'Заявитель',
};

const theme = useOverriddenTheme(window.env.COUNTRY_CODE);

const commonOption = [
  { fullName: 'Без исполнителя', id: '-1' },
]

export const getColumns = (
  toWork: (id: number) => void,
  applicationConfig: ApplicationConfig,
  tab: Tab,
  currentUserId: number,
  productGroupsOptions: ISelectOptions,
  categoryListOptions: ISelectOptions,
  packageTypeOptions: ISelectOptions,
  lang: Language,
  selectedFilterPg: string | null | undefined,
  setSelectedFilterPg?: (v: string) => void,
  allModerators: ISelectOptions
): Column<TaskTableData>[] => {
  return [
    {
      id: 'gtinColumn',
      Header: Translate('GTIN'),
      Container: {
        component: Stack,
        props: { gap: 1 },
      },
      merge: true,
      disableFilters: true,
      accessor: (row) => row.gtin,
      columns: [
        {
          id: 'gtin',
          Header: Translate('GTIN'),
          Container: { component: Cell },
          accessor: (row) => row.gtin,
          Cell: ({ row }) => (
            <Link href={`/tasks/${row.original.id}`}>{row.original.gtin}</Link>
          ),
          Filter: makeGtinFilter() as unknown,
        },
        {
          id: 'productName',
          Header: Translate('Наименование товара'),
          Container: { component: Cell },
          accessor: (row) => row.productName,
        },
      ],
    },
    {
      id: 'participantInn',
      Header: Translate('Заявитель'),
      Container: {
        component: Stack,
        props: { gap: 1 },
      },
      merge: true,
      accessor: (row) => row.participant.inn,
      columns: [
        {
          id: 'owner_inn',
          Header: Translate('ИНН'),
          Container: { component: Cell },
          accessor: (row) => row.participant.inn,
          Cell: ({ row }) => (
            <Link href={`/participants/${row.original.participant.inn}`}>
              {row.original.participant.inn}
            </Link>
          ),
        },
        {
          id: 'participantName',
          Header: Translate('Наименование участника'),
          Container: { component: Cell },
          accessor: (row) => row.participantName,
        },
      ],
    },
    {
      id: 'productGroupCode',
      Header: Translate('Товарная группа'),
      Container: { component: Cell },
      accessor: (row) => row.productGroupName,
      Filter: makeAsyncLookupFilter({
        title: Translate('Товарная группа'),
        searchOptions: (name) =>
          API.rest.productGroups.nameSuggest({ name, language: lang }),
        defaultOptions: productGroupsOptions,
        getOptionLabel: (data) => {
          return data?.name[lang];
        },
        getOptionValue: (data) => data?.code,
        fetchValue: (data) => data,
        minValueLength: 2,
        classes: {
          paper: css.asyncLookupInput,
        },
        onChangeValue: (v) => {
          setSelectedFilterPg && setSelectedFilterPg(v ? String(v) : '');
        },
      }),
    },
    {
      id: 'category',
      Header: Translate('Категория товара'),
      Container: { component: Cell },
      accessor: (row) => row.productCategoryName ?? '—',
      Filter: makeAsyncLookupFilter({
        title: Translate('Категория товара'),
        searchOptions: (name) =>
          API.rest.category.nameSuggest({
            name,
            language: lang,
            productGroup: selectedFilterPg || null,
          }),
        defaultOptions: categoryListOptions,
        getOptionLabel: (data) => data?.name[lang],
        getOptionValue: (data) => data?.code,
        fetchValue: (data) => data,
        minValueLength: 2,
        classes: {
          paper: css.asyncLookupInput,
        },
        watchExactValueInOptions: true,
      }),
    },
    {
      id: 'packageType',
      Header: Translate('Тип упаковки'),
      Container: { component: Cell },
      accessor: (row) => row.packageType,
      disableSortBy: true,
      Filter: makeSelectFilter({
        options: packageTypeOptions,
        resetText: Translate('Сбросить фильтр'),
        theme,
      }),
    },
    {
      id: 'date',
      Header: Translate('Дата создания'),
      Container: { component: Cell },
      accessor: (row) => row.dateCreate,
      Filter: (() =>
        makeDateRangeFilter({
          startLabel: 'От',
          endLabel: 'До',
          keyFrom: 'dateCreateFrom',
          keyTo: 'dateCreateTo',
          CustomInput: InputForDatePicker,
          maxDate: moment().endOf('day').toDate(),
        }))(),
    },
    ...(applicationConfig.tasks.isAdministration && tab === 'MODERATION'
      ? [
        {
          id: assignedUserId,
          Header: Translate('Исполнитель'),
          accessor: (row) => row.assignedUserName,
          Filter: makeAsyncLookupFilter({
            defaultOptions: [...commonOption, ...allModerators],
            title: Translate('Исполнитель'),
            searchOptions: API.rest.tasks.userSuggest,
            getOptionLabel: (data) => data?.fullName,
            getOptionValue: (data) => data,
            fetchValue: (data) => data,
            classes: {
              paper: css.asyncLookupInput,
            },
          }),
          Cell: ({ row }) => (
            <div className={css['assigned-user']}>
              <div className={css['assigned-user-name']}>
                {row.original.assignedUserName}
              </div>
              <div className={css['assigned-user-icon']}></div>
            </div>
          ),
        } as Column<TaskTableData>,
      ]
      : []),
    ...(applicationConfig.tasks.isAdministration && tab === 'ALL'
      ? [
        {
          id: assignedUserId,
          Header: Translate('Исполнитель'),
          accessor: (row) => row.assignedUserName,
          Filter: makeAsyncLookupFilter({
            title: Translate('Исполнитель'),
            defaultOptions: [...commonOption, ...allModerators],
            searchOptions: API.rest.tasks.userSuggest,
            getOptionLabel: (data) => data?.fullName,
            getOptionValue: (data) => data,
            fetchValue: (data) => data,
            classes: {
              paper: css.asyncLookupInput,
            },
          }),
          Cell: ({ row }) => (
            <>
              <div className={css['assigned-user-name']}>
                {row.original.assignedUserName ?? '—'}
              </div>
              <div className={css['date-finish']}>
                {row.original.dateFinish}
              </div>
            </>
          ),
        } as Column<TaskTableData>,
      ]
      : []),
    ...(applicationConfig.tasks.canRead && tab === 'ALL'
      ? [
        {
          id: 'checkedUserId',
          Header: Translate('Проверил'),
          accessor: (row) => row.assignedUserName,
          Cell: ({ row }) => (
            <>
              <div className={css['assigned-user-name']}>
                {row.original.assignedUserName ?? '—'}
              </div>
              <div className={css['date-finish']}>
                {row.original.dateTimeFinish}
              </div>
            </>
          ),
        } as Column<TaskTableData>,
      ]
      : []),
    {
      id: 'status',
      Header: Translate('Статус модерации'),
      Container: { component: Cell },
      Filter: makeMultiSelectFilter({
        options: tab === 'MODERATION' ? moderationStatusOptions : statusOptions,
        theme,
      }),
      accessor: (row) => row.status,
      Cell: ({ row }) => <ChipStatusTask status={row.original.status} />,
    },
    ...(applicationConfig.tasks.canWrite && tab === 'MODERATION'
      ? [
        {
          id: 'actions',
          accessor: (row) => row.actions,
          Header: Translate('Действия'),
          disableSortBy: true,
          disableConfig: true,
          Filter: null,
          Cell: ({ row }) => {
            return currentUserId === row.original.assigned_user?.id &&
            row.original.status === 'CREATED' ? (
              <Button
                classes={themeButton}
                color="primary"
                variant="contained"
                onClick={() => toWork(row.original.id)}
              >
                {Translate('В работу')}
              </Button>
            ) : null;
          },
        },
      ]
      : []),
  ];
}
