import fp from 'lodash/fp';

import { POINT_OF_ENTER } from '../constants';
import { COUNTRIES } from '../constants/countries';
import { photoCodes } from '../pages/products/constants';
import { getPhotoLink } from '../store/reducers/products';

import { b64DecodeUnicode, getCookie } from './cookieHelper';

export const getParamFromToken = (param, json, tokenType = 'access_token') => {
  const data = getTokenData(json, tokenType);
  return data[param];
};

export const getTokenData = (json, tokenType) => {
  const fullToken = json[tokenType];
  const token = fullToken.split('.')[1];
  return JSON.parse(Buffer.from(token, 'base64').toString());
};

export const getShortName = (fullName) =>
  fullName
    .split(' ')
    .map((item, idx) => (idx === 0 ? item : `${item[0]}.`))
    .join(' ');

export const getCountryName = (country) => {
  const countryCode = country?.toUpperCase();
  return fp.pipe(
    fp.find((item) => item.value === countryCode),
    fp.get('label')
  )(COUNTRIES);
};

export const prepareDefaultFilter = (filters) =>
  Object.keys(filters)
    .map((key) => {
      const id = key.includes('Start') ? key.replace('Start', '') : key;
      const value = key.includes('Start')
        ? { startDate: filters[`${id}Start`], endDate: filters[`${id}End`] }
        : filters[key];
      return {
        id,
        value,
      };
    })
    .filter((item) => !item.id.includes('End'));

export function getToken() {
  const count = getCookie('count') || 0;
  const token = new Array(+count)
    .fill(1)
    .map((_v, i) => getCookie(`tokenPart${i + 1}`))
    .join('');

  return count ? token : null;
  // const token = localStorage.getItem('accessToken');
  // if (!token) {
  //   localStorage.setItem('accessToken', defaultToken);
  // }
  // return token || defaultToken;
}

export function getTokenPayload() {
  const fullToken = getToken();
  if (!fullToken) {
    window.open(`${window.env.REACT_APP_ELK_URL}${POINT_OF_ENTER}`, '_self');
    return {};
  }
  const token = fullToken.split('.')[1];
  return JSON.parse(b64DecodeUnicode(token));
}

export const handleAllowDrop = (e) => e.preventDefault();

export const preparePhoto = (atrr) => {
  return atrr.reduce((acc, item) => {
    if (
      photoCodes.includes(item.attribute.code) &&
      item?.value?.files?.length
    ) {
      const photos = item.value.files.map((photo) => ({
        name: photo.fileName,
        side: item.attribute.code,
        src: getPhotoLink(photo.fileName),
      }));
      acc = [...acc, ...photos];
    }
    return acc;
  }, []);
};

export const prepareDocs = (json) => {
  const docs = [...json?.optional_attributes].find(
    (el) => el.attribute.code === 'technical_photo'
  );

  if (docs?.value?.files?.length) {
    return docs.value.files.map((doc) => ({
      ...doc,
      src: getPhotoLink(doc.fileName),
    }));
  }
  return [];
};

export const getDownloadLink = (fileName) => {
  const domain =
    process.env.NODE_ENV === 'development'
      ? window.env.REACT_APP_RMT_URL
      : window.location.origin;
  return `${domain}/photo/${fileName}`;
};

export const prepareComments = (list) => {
  const commentObj = {};
  list.forEach((item) => {
    commentObj[item.attribute] = {
      reason: {
        ...(commentObj[item.attribute]
          ? commentObj[item.attribute].reason
          : {}),
        [item?.lang]: item.typicalComment?.id || 'other',
      },
      customComment: {
        ...(commentObj[item.attribute]
          ? commentObj[item.attribute].customComment
          : {}),
        [item?.lang]: item.customComment,
      },
      typicalComment: {
        ...(commentObj[item.attribute]
          ? commentObj[item.attribute].typicalComment
          : {}),
        [item?.lang]: item?.typicalComment?.value[item?.lang],
      },
      id: item.id,
    };
  });
  return commentObj;
};
