import qs from 'qs';

function request(url) {
  return (path = '', query = {}) => {
    const queryString = qs.stringify(query);
    return encodeURI(
      `${url}${path ? `/${path}` : ''}${queryString ? `?${queryString}` : ''}`
    );
  };
}

export const routes = {
  home: request('/'),
  participants: request('/participants'),
  fiduciaryParticipant: request('/fiduciary-participant'),
  fiduciaryParticipantView: request('/fiduciary-participant-view'),
  prefixes: request('/prefixes'),
  products: request('/products'),
  tasks: request('/tasks'),
  productGroups: request('/productGroups'),
  serverError: request('/server-error'),
};
