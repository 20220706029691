export const palette = {
  grey: {
    100: '#181f38',
    90: '#4b5168',
    70: '#555d67',
    60: '#7a819b',
    50: '#8da8c9',
    40: '#c9d6e2',
    30: '#dce6f0',
    20: '#e9eff5',
    10: '#eeeff0',
    '05': '#f3f6f9',
    5: '#f3f6f9',
    '00': '#ffffff',
    0: '#ffffff',
  },
  blue: {
    100: '#144ea6',
    90: '#2866c3',
    70: '#3474d3',
  },
  /*
   * primary = yellow
   * В @crpt/material цвета захардкожены как theme.palette.yellow['40'].
   * Из-за этого считаем yellow как primary цвет
   * */
  yellow: {
    100: '#562e77',
    60: '#614c77',
    40: '#614c77',
    10: 'rgba(37,94,150,0.49)',
  },
  green: {
    70: '#009933',
    60: '#00CC66',
    20: '#CBFFDD',
  },
  orange: {
    70: '#f08d1b',
    20: '#ffebcc',
  },
  red: {
    70: '#f34747',
    60: '#ff7474',
    20: '#ffe4e4',
  },
  text: {
    primary: '#1F1F1F',
    secondary: '#888888',
    contrastText: '#ffffff',
    disabled: '#7A819B',
  },
};
