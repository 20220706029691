/* eslint-disable */
import { put, call /*, take */ } from 'redux-saga/effects';
import fp from 'lodash/fp';
// import { replace } from 'connected-react-router';
// import moment from 'moment';
import API from '../../api';
import { clearUser, setAuthData, setUser, setUserData } from '../actions/auth';
// import { routes } from '../../routes';
import { getParamFromToken, getToken } from '../../utils/utils';
import { deleteAllCookies } from '../../utils/cookieHelper';
import { POINT_OF_ENTER } from '../../constants';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';

export function storeTokens({
  access_token,
  exp_date,
  refresh_token,
  exp_date_refresh,
} = {}) {
  localStorage.setItem('access_token', access_token || '');
  // localStorage.setItem('refresh_token', refresh_token || '');
  // localStorage.setItem('exp_date', exp_date || '');
  // localStorage.setItem('exp_date_refresh', exp_date_refresh || '');
  localStorage.setItem('token_type', 'Bearer');
}

function* setAuthDataSaga(json) {
  try {
    const exp_date = getParamFromToken('exp', json, 'access_token');
    const exp_date_refresh = getParamFromToken('exp', json, 'refresh_token');
    storeTokens({ ...json, exp_date, exp_date_refresh });
    yield put(
      setAuthData({
        ...json,
        exp_date,
        exp_date_refresh,
      })
    );
    yield put(setUser({ ...json }));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* loginSaga(action) {
  try {
    const json = yield API.rest.auth.signin(action.payload);
    yield call(setAuthDataSaga, json);
    // yield call(loadProfileSaga);
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* logoutSaga() {
  try {
    window.open(`${window.env.REACT_APP_ELK_URL}${POINT_OF_ENTER}`, '_self');
    storeTokens();
    yield put(setAuthData({ isNotAuth: true }));
    yield put(clearUser());
    deleteAllCookies();
    // yield put(replace({ pathname: routes.home(), search: null }));
    // eslint-disable-next-line no-empty
  } catch (err) {
    console.log('logoutSaga err', err);
  }
}

export function* checkAuthSaga(action) {
  try {
    const token = getToken();
    const data = fp.omitBy(fp.isEmpty, {
      access_token: token || '',
      // refresh_token: localStorage.getItem('refresh_token') || '',
      // exp_date: localStorage.getItem('exp_date') || '',
      // exp_date_refresh: localStorage.getItem('exp_date_refresh') || '',
    });
    if (data.access_token) {
      const user = JSON.parse(
        Buffer.from(token.split('.')[1], 'base64').toString()
      );
      yield put(setAuthData({ ...data }));
      yield put(setUserData({ ...user }));
      yield put(setUser({ access_token: data.access_token }));
      yield action.payload.onFinish(false);
      // const currentDate = moment().unix();
      // if (moment(currentDate).isBefore(parseInt(data.exp_date, 10))) {
      //   yield put(setAuthData({ ...data }));
      //   yield put(setUser({ access_token: data.access_token }));
      //   yield action.payload.onFinish(false);
      // } else {
      //   if (moment(currentDate).isBefore(parseInt(data.exp_date_refresh, 10))) {
      //     const json = yield API.rest.auth.reconnect(data.refresh_token);
      //     yield call(setAuthDataSaga, json);
      //     yield action.payload.onFinish(false);
      //   } else {
      //     yield put(setAuthData({ isNotAuth: true }));
      //     storeTokens();
      //     yield action.payload.onFinish(false);
      //   }
      // }
    } else {
      window.open(`${window.env.REACT_APP_ELK_URL}${POINT_OF_ENTER}`, '_self');
      // yield put(setAuthData({ isNotAuth: true }));
      // action.payload.onFinish(true);
    }
  } catch (err) {
    window.open(`${window.env.REACT_APP_ELK_URL}${POINT_OF_ENTER}`, '_self');
    // yield put(setAuthData({ isNotAuth: true }));
    // action.payload.onFinish(true);
  }
}
