import { createAction } from '../../helpers/redux';
import { types } from '../types';

export const setItem = createAction(types.SET_PRODUCT_GROUPS_ITEM);
export const clear = createAction(types.CLEAR_PRODUCT_GROUPS_ITEM);
export const setCodes = createAction(types.SET_CODES);
export const getItem = createAction(types.GET_PRODUCT_GROUPS_ITEM);
export const getAttributes = createAction(types.GET_ATTRIBUTES); // used in useProductGroupDetailsPage
export const setAttributes = createAction(types.SET_ATTRIBUTES);
export const getCodes = createAction(types.GET_CODES); // used in useProductGroupDetailsPage
export const getPackageLevels = createAction(types.GET_PACKAGE_LEVELS); // used in useProductGroupDetailsPage
export const setPackageLevels = createAction(types.SET_PACKAGE_LEVELS);
export const getCategories = createAction(types.GET_PG_CATEGORIES); // used in useProductGroupDetailsPage
export const setCategories = createAction(types.SET_PG_CATEGORIES);
export const setPackageType = createAction(types.SET_PACKAGE_TYPE);
export const getPGCurrentCategory = createAction(types.GET_PG_CURRENT_CATEGORY);
export const setCurrentCategory = createAction(types.SET_PG_CURRENT_CATEGORY);
