import { styled } from '@mui/material';
import React from 'react';

import Button, { buttonClasses, ButtonProps } from './Button';

export const LandscapeButton = styled(({ children, ...rest }: ButtonProps) => (
  <Button
    disableClone
    menuProps={{
      transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
      },
      sx: {
        ml: '8px',
      },
    }}
    {...rest}
  >
    {({ open, chevronIcon }) => {
      return open ? (
        <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.893 1.693a1 1 0 1 1 1.414 1.414L9.414 8l4.893 4.893a1 1 0 1 1-1.414 1.414L8 9.414l-4.893 4.893a1 1 0 1 1-1.414-1.414L6.586 8 1.693 3.107a1 1 0 1 1 1.414-1.414L8 6.586l4.893-4.893z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <>
          {children}
          {chevronIcon}
        </>
      );
    }}
  </Button>
))(({ theme }) => ({
  background: theme.palette.primary.main,
  margin: '0 8px 0 0',
  padding: '0 0 0 8px',
  width: 56,

  '&::after': {
    content: '""',
    borderStyle: 'solid',
    borderWidth: '20px 0 20px 8px',
    borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
    width: 0,
    height: 0,
    position: 'absolute',
    top: 0,
    right: -8,
  },

  '&:hover': {
    color: 'inherit',
  },

  [`&.${buttonClasses.open}`]: {
    background: theme.palette.primary.main,

    '&::after': {
      background: theme.palette.primary.main,
    },
  },
}));
