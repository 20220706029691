import moment from 'moment';

import API from '../../api';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';

export function* downloadFileSaga(action) {
  try {
    const date = moment().format('YYYYMMDDHHmmss');
    const fileName = `_download_${date}.csv_`;
    yield API.rest.files.download(action.payload, fileName);
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* uploadFileSaga(action) {
  try {
    const json = yield API.rest.codes.validate(
      [...action.payload.files],
      '',
      action.payload.query
    );
    action.payload.onFinish(json);
  } catch (err) {
    action.payload.onFinish(false);
    yield Toast.showError({ errors: err });
  }
}
