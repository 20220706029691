import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { debounce, isEmpty } from 'lodash';

import { AutocompleteProps, Box, Throbber, useTheme } from '@crpt/material';

import { WithScrollClose } from '../../../HOC/with-scroll-close';

import Autocomplete from './Autocomplete';

import { useEvent } from '../../../../utils/react.hooks';
import { AutocompleteInputChangeReason } from '@material-ui/lab';
import styles from './asyncLookupInput.module.scss';

export interface AsyncLookupInputProps<
  Value,
  Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined = false
> extends Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, false>,
    | 'options'
    | 'filterOptions'
    | 'getOptionLabel'
    | 'renderOptionItem'
    | 'inputValue'
    | 'loading'
  > {
  searchOptions: (inputValue: string, signal: AbortSignal) => Promise<Option[]>;
  defaultOptions?: Option[];
  fetchValue: (
    value: Value,
    signal: AbortSignal
  ) => Promise<Option | undefined | null>;
  searchTrigger?: (inputValue: string) => boolean;
  getOptionValue?: (option: Option) => Value;
  getOptionLabel?: (option: Option) => string;
  renderOptionItem?: (option: Option) => React.ReactNode;
  delay?: number;
  errorText?: React.ReactNode;
  minValueLength?: number;
  freeSolo: boolean;
  validateInputValue?: (value: string) => boolean;
  maxMultipleListLength?: number;
}

interface LookupInputState<T> {
  optionValues: T[];
  loading: boolean;
  error?: boolean;
}

const defaultState: LookupInputState<unknown> = {
  loading: false,
  error: false,
  optionValues: [],
};

export interface AsyncLookupInputDefaultOption<Value> {
  label?: string;
  name?: string;
  value: Value;
}

const Component = <
  Value,
  Option extends Record<string, unknown> = AsyncLookupInputDefaultOption<Value>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false
>(
  props: AsyncLookupInputProps<Value, Option, Multiple, DisableClearable>
) => {
  const { minValueLength = 3, validateInputValue, ...componentProps } = props;
  const {
    delay = 350,
    fetchValue,
    searchOptions,
    defaultOptions,
    searchTrigger = (inputValue) => inputValue.length >= minValueLength,
    getOptionValue = ({ value }) => value,
    getOptionLabel = ({ label, name, value }) => label || name || value,
    renderOptionItem,
    errorText = <ErrorText />,
    noOptionsText,
    InputProps = (props) => props,
    freeSolo,
    maxMultipleListLength,
    ...autocompleteProps
  } = componentProps;
  const [lookupInputState, setLookupInputState] =
    useState<LookupInputState<Value>>(defaultState);
  const { loading, error, optionValues } = lookupInputState;
  const abortCtrlRef = useRef<AbortController>();
  const initValuesRef = useRef<boolean>(false);
  const [optionRegistry, setOptionRegistry] = useState<Map<Value, Option>>(
    new Map()
  );
  const [inputValue, setInputValue] = useState<string>('');

  const addOptionsToRegistry = useCallback(
    (options: Option[]) => {
      if (options?.length > 0) {
        setOptionRegistry((optionRegistry) => {
          return options.reduce<Map<Value, Option>>(
            (map, option) => map.set(getOptionValue(option), option),
            new Map(optionRegistry)
          );
        });
      }
    },
    [getOptionValue]
  );

  const createSetStateCtrl = useCallback(
    (abortCtrl: AbortController) => {
      function setState(state: LookupInputState<Value>) {
        if (abortCtrlRef.current === abortCtrl) {
          setLookupInputState(state);
        }
      }
      return {
        setLoading: () => {
          setState({ optionValues: [], loading: true });
        },
        setOptions: (options: Option[]) => {
          addOptionsToRegistry(options);
          const optionValues = options.map(getOptionValue);
          setState({ optionValues, loading: false });
        },
        setError: (error: unknown) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(error);
          }
          setState({ optionValues: [], loading: false, error: true });
        },
      };
    },
    [addOptionsToRegistry, getOptionValue]
  );

  const handleInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    let currentValue;
    if (validateInputValue) {
      currentValue = validateInputValue(value) ? inputValue : value;
    } else {
      currentValue = value;
    }
    setInputValue(currentValue);

    abortCtrlRef.current?.abort();
    const abortCtrl = new AbortController();
    abortCtrlRef.current = abortCtrl;
    const { setOptions } = createSetStateCtrl(abortCtrl);

    if (reason === 'input') {
      debouncedFetchOptions(currentValue, abortCtrl);
    } else {
      setOptions(defaultOptions ?? []);
    }

    props.onInputChange?.(event, currentValue, reason);
  };

  const onBlur = useEvent(() => {
    if (freeSolo && inputValue) {
      props.onChange(_, inputValue);
    }
  });

  useEffect(() => {
    if (!initValuesRef.current) {
      initValuesRef.current = true;
      const abortCtrl = new AbortController();
      abortCtrlRef.current = abortCtrl;
      const requests = (valueToArray(props.value) as Value[])
        .filter((value) => !optionRegistry.has(value))
        .map((value) => fetchValue(value, abortCtrl.signal));
      if (requests.length > 0) {
        const { setLoading, setOptions, setError } =
          createSetStateCtrl(abortCtrl);
        setLoading();
        Promise.all(requests)
          .then((options) => {
            const optionList = options.filter((option): option is Option =>
              hasValue(option)
            );
            setOptions(optionList);
            return options;
          })
          .catch(setError);
      }
      return () => abortCtrl.abort();
    }
  }, [createSetStateCtrl, fetchValue, optionRegistry, props.value]);

  const changeDisabled =
    props.multiple &&
    maxMultipleListLength !== undefined &&
    autocompleteProps?.value?.length >= maxMultipleListLength;

  const debouncedFetchOptions = useMemo(() => {
    async function fetch(inputValue: string, abortCtrl: AbortController) {
      const { setLoading, setOptions, setError } =
        createSetStateCtrl(abortCtrl);
      setLoading();
      const normalizedValue = (inputValue || '').trim();
      const isTriggered = searchTrigger?.(normalizedValue);

      if (normalizedValue.length < minValueLength && !isEmpty(defaultOptions)) {
        setOptions(defaultOptions);
      }

      try {
        const options =
          isTriggered && !changeDisabled
            ? await searchOptions(normalizedValue, abortCtrl.signal)
            : [];
        if (normalizedValue.length >= minValueLength) {
          setOptions(options);
        }
      } catch (error) {
        setError(error);
      }
    }
    return debounce(fetch, delay);
  }, [createSetStateCtrl, delay, searchOptions, searchTrigger, defaultOptions]);

  useEffect(() => {
    if (defaultOptions) {
      const abortCtrl = new AbortController();

      abortCtrlRef.current?.abort();
      abortCtrlRef.current = abortCtrl;
      const { setOptions } = createSetStateCtrl(abortCtrl);
      setOptions(defaultOptions ?? []);

      return () => abortCtrl?.abort();
    }
  }, [createSetStateCtrl, defaultOptions]);

  const componentsProps = useMemo(() => {
    if (defaultOptions) {
      return props.componentsProps;
    }
    const isTriggered = searchTrigger?.(inputValue.trim());
    return !isTriggered || loading
      ? { paper: { sx: { display: 'none' } } }
      : props.componentsProps;
  }, [
    inputValue,
    loading,
    props.componentsProps,
    searchTrigger,
    defaultOptions,
  ]);
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    if (changeDisabled) {
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    abortCtrlRef.current?.abort();
    setOpen(false);
  };

  return (
    <WithScrollClose handleClose={handleClose}>
      <Autocomplete
        {...autocompleteProps}
        freeSolo={freeSolo}
        className={props.multiple && styles.multiple}
        open={open}
        onOpen={onOpen}
        InputProps={(props) => {
          return InputProps?.({
            ...props,
            endAdornment:
              loading && inputValue.length >= minValueLength ? (
                <Throbber
                  sx={{ position: 'absolute', right: 16 }}
                  variant="blue"
                />
              ) : (
                props.endAdornment
              ),
          });
        }}
        componentsProps={componentsProps}
        filterOptions={(optionValues) => optionValues}
        getOptionLabel={(optionValue) => {
          if (!loading || autocompleteProps?.multiple) {
            const option = optionRegistry.get(optionValue);
            return option ? getOptionLabel(option) : optionValue;
          }
          return '';
        }}
        inputValue={inputValue}
        loading={loading}
        noOptionsText={error ? errorText : noOptionsText}
        options={optionValues}
        renderOptionItem={(optionValue) => {
          const option = optionRegistry.get(optionValue);
          return option ? renderOptionItem?.(option) : null;
        }}
        onClose={(event, reason) => {
          handleClose();
          props.onClose?.(event, reason);
        }}
        onInputChange={handleInputChange}
        onBlur={onBlur}
        disablePortal={true}
      />
    </WithScrollClose>
  );
};

function hasValue<T>(value?: T | null | ''): boolean {
  return value !== null && value !== '';
}

function valueToArray<T>(value?: T | T[] | null): T[] {
  if (Array.isArray(value)) {
    return value;
  }
  return hasValue(value) ? [value as T] : [];
}

function ErrorText() {
  const theme = useTheme();
  const color = theme.palette.red[70];
  return <Box color={color}>Возникла ошибка при загрузке данных</Box>;
}

export const AsyncLookupInput = memo(Component) as unknown as typeof Component;
