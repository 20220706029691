import React, { useCallback, useMemo, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import { Link } from '@crpt-ui/core';

import { routes } from '../../routes';

import styles from './index.module.scss';

export const ServerErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const code = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get('code') || '500';
  }, [location]);

  const isServerError = useMemo(() => code[0] === '5', [code]);

  const { message, comment } = useMemo(() => {
    let message = '';
    let comment: string | ReactElement = '';
    switch (code) {
      case '403':
        message = 'Доступ запрещен';
        comment = t(
          'Недостаточно прав для выполнения операции. Обратитесь к своему администратору.'
        );
        break;
      case '404':
        message = 'Запрашиваемый объект не найден';
        break;
      default:
        if (isServerError) {
          message = 'Технические неполадки';
          comment = (
            <>
              {t('Системная ошибка. Попробуйте обновить страницу.')}
              <br />
              {t('Если проблема сохраняется, обратитесь в службу поддержки.')}
            </>
          );
          return { message, comment };
        }
        message = '';
    }
    return { message, comment };
  }, [code, isServerError, t]);

  const goBack = useCallback(() => history.goBack(), [history]);

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.line} />
      <div className={styles.stroke}>
        <h2>{code}</h2>
        <h3>{t(message)}</h3>
      </div>
      {comment && <p>{comment}</p>}
      {isServerError ? (
        <button className={styles.transfer} type="button" onClick={goBack}>
          {t('Обновить страницу')}
        </button>
      ) : (
        <Link
          className={styles.transfer}
          component={RouterLink}
          to={routes.products()}
        >
          {t('Перейти на главную')}
        </Link>
      )}
    </div>
  );
};
