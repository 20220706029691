import React from 'react';

import cn from 'classnames';

import css from './cell.module.scss';

type CellProps = {
  className?: string;
  children: React.ReactNode;
};

export const Cell: React.FC<CellProps> = ({ children, className }) => {
  const title = typeof children === 'string' ? children : '';
  return (
    <span className={cn(css.container, className)} title={title}>
      {children}
    </span>
  );
};
