import {
  Box,
  ButtonBase,
  buttonBaseClasses,
  ButtonBaseProps,
  Menu,
  menuClasses,
  MenuItem as MuiMenuItem,
  menuItemClasses,
  MenuItemProps as MuiMenuItemProps,
  MenuProps,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import React, {
  ElementType,
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    boxShadow: '0px 4px 16px 0px rgba(0,0,0,.15)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    '&::-webkit-scrollbar': {
      width: 4,
    },

    '&::-webkit-scrollbar-track': {
      background: '#f2f2f2',
    },

    '&::-webkit-scrollbar-thumb': {
      width: 2,
      borderRadius: 2,
      background: theme.palette.text.primary,
    },

    [`& .${menuClasses.list}`]: {
      padding: 0,
    },
  },
}));

interface MenuItemProps extends MuiMenuItemProps {
  icon?: ReactNode;
}

const MenuItem: FC<MenuItemProps> = ({ icon, children, ...rest }) => (
  <MuiMenuItem {...rest}>
    {icon ? (
      <Box
        sx={{
          marginRight: '8px',
        }}
      >
        {icon}
      </Box>
    ) : null}
    {children}
  </MuiMenuItem>
);

const StyledMenuItem = styled(MenuItem)({
  [`&.${menuItemClasses.root}`]: {
    fontSize: 12,
    height: 40,
    padding: '0 16px',
    fontWeight: 600,

    '&:hover': {
      background: '#f7f7f7',
    },
  },
});

export const buttonClasses = {
  ...buttonBaseClasses,
  icon: 'icon',
  open: 'open',
  selected: 'selected',
};

export interface ButtonProps extends ButtonBaseProps {
  component?: ElementType;
  icon?: ReactNode;
  menuItems?: MenuItemProps[];
  menuProps?: Omit<MenuProps, 'open'>;
  selected?: boolean;
  disableClone?: boolean;
  children:
    | ((props: { chevronIcon: ReactNode; open: boolean }) => ReactNode)
    | ReactNode;
}

const ChevronIcon = styled((props) => (
  <span {...props}>
    <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.293 6.293a1 1 0 0 1 1.32-.083l.094.083L8 8.585l2.293-2.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094-3 3a1 1 0 0 1-1.32.083l-.094-.083-3-3a1 1 0 0 1 0-1.414z" />
    </svg>
  </span>
))({
  marginLeft: 8,

  '& svg': {
    fill: 'currentColor',
    verticalAlign: 'top',
  },
});

export const Button = styled(
  ({
    children,
    className,
    icon,
    menuItems = [],
    menuProps,
    onClick,
    disableClone,
    selected,
    ...rest
  }: ButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const ref = useRef<HTMLButtonElement>(null);

    const renderMenu = useCallback(() => {
      if (menuItems.length) {
        return (
          <StyledMenu
            PaperProps={{
              sx: {
                minWidth: ref.current?.offsetWidth,
              },
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            disableAutoFocus
            disableAutoFocusItem
            marginThreshold={0}
            onClose={() => setAnchorEl(null)}
            open={Boolean(anchorEl)}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            transitionDuration={0}
            {...menuProps}
          >
            {disableClone ? null : (
              <StyledMenuItem
                key="root"
                sx={{
                  pointerEvents: 'none',
                }}
              >
                {children}
                <ChevronIcon
                  sx={{
                    ml: 'auto',
                    transform: 'rotate(180deg)',
                  }}
                />
              </StyledMenuItem>
            )}
            {menuItems.map((menuItem) => (
              <StyledMenuItem
                disableRipple
                disableTouchRipple
                key={menuItem.key}
                {...menuItem}
                onClick={(event) => {
                  menuItem.onClick && menuItem.onClick(event);
                  setAnchorEl(null);
                }}
              />
            ))}
          </StyledMenu>
        );
      }
      return null;
    }, [anchorEl, children, disableClone, menuItems, menuProps]);

    const handleClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (menuItems.length) {
          setAnchorEl(event.currentTarget);
        }

        onClick && onClick(event);
      },
      [menuItems.length, onClick]
    );

    const renderChildren = useCallback(() => {
      if (typeof children === 'function') {
        return children({
          open: !!anchorEl,
          chevronIcon: <ChevronIcon />,
        });
      }

      return (
        <>
          {children}
          {menuItems.length ? <ChevronIcon /> : null}
        </>
      );
    }, [children, menuItems.length, anchorEl]);

    return (
      <>
        <ButtonBase
          className={clsx(
            {
              [buttonClasses.selected]: selected,
              [buttonClasses.open]: Boolean(anchorEl),
            },
            className
          )}
          component="a"
          disableRipple
          disableTouchRipple
          ref={ref}
          role="menuitem"
          {...rest}
          onClick={handleClick}
        >
          {icon ? <span className={buttonClasses.icon}>{icon}</span> : null}
          {renderChildren()}
        </ButtonBase>
        {renderMenu()}
      </>
    );
  }
)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: 12,
  fontWeight: 600,
  lineHeight: 16 / 12,
  padding: '0 8px',
  height: 40,
  transition: theme.transitions.create(['box-shadow', 'color'], {
    duration: theme.transitions.duration.short,
  }),

  [`& .${buttonClasses.icon}`]: {
    color: '#b8b8b8',
    margin: '0 8px 0 0',
  },

  [`&:hover, &.${buttonClasses.selected}`]: {
    color: theme.palette.primary.main,
  },
}));

export default Button;
