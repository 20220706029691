import React, { useEffect, useMemo, useState } from 'react';

import fp from 'lodash/fp';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tab } from '@crpt/material';

import { routes } from '../../../routes';
import { Tabs } from '../../crpt-ui-wrappers/tabs';
import {
  Page,
  useApplicationConfig,
} from '../../application-config/application-config';
import { useEvent } from '../../../utils/react.hooks';
import { RootState } from '../../../store/reducers';
import * as settingsSelectors from '../../../store/reducers/settings.selectors';

type MenuData = {
  id: Page;
  label: string;
  value: string;
};

const MENU_ITEMS: MenuData[] = [
  {
    id: 'participants',
    label: 'Участники товарооборота',
    value: routes.participants(),
  },
  {
    id: 'prefixes',
    label: 'Префиксы GS1 (GCP)',
    value: routes.prefixes(),
  },
  {
    id: 'products',
    label: 'Товары',
    value: routes.products(),
  },
  {
    id: 'tasks',
    label: 'Модерация товаров',
    value: routes.tasks(),
  },
  {
    id: 'productGroups',
    label: 'Товарные группы',
    value: routes.productGroups(),
  },
];

const MENU_VALUES = MENU_ITEMS.map(({ value }) => value);

const DEFAULT_TAB = routes.products();

export const PageMenu = React.memo(() => {
  const applicationConfig = useApplicationConfig();
  const [value, setValue] = useState(DEFAULT_TAB);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const language = useSelector<RootState, RootState['settings']['language']>(
    settingsSelectors.language
  );

  useEffect(() => {
    const [_divider, tabNameFromLocation] = location.pathname.split('/');
    const valueFromLocation = `/${tabNameFromLocation}`;
    MENU_VALUES.includes(valueFromLocation) && setValue(valueFromLocation);
    if (
      valueFromLocation === routes.fiduciaryParticipantView() ||
      valueFromLocation === routes.fiduciaryParticipant()
    ) {
      setValue(routes.participants());
    }
  }, [location]);

  const menuList = useMemo(
    () =>
      MENU_ITEMS.filter((item) => applicationConfig[item.id].canRead).map(
        (item) => ({ ...item, label: t(item.label) })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationConfig, t, language]
  );

  const handleClick = useEvent((newValue: string) => history.push(newValue));

  return (
    <div>
      <Tabs value={value} onChange={fp.stubTrue}>
        {menuList.map((item) => (
          <Tab
            key={item.value}
            label={item.label}
            value={item.value}
            onClick={() => handleClick(item.value)}
          />
        ))}
      </Tabs>
    </div>
  );
});
