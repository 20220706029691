import React from 'react';

import { Icons } from '../../../components/Icon';
import { ChipStatus, ChipStatusColor } from '../../../components/chip-status';
import { Translate } from '../../../components/translate';
import { TaskTableData } from '../index.columns';

import css from './chip-status-task.module.scss';

type ChipStatusTaskProps = Pick<TaskTableData, 'status'>;
type Status = ChipStatusTaskProps['status'];

const STATUS_TO_COLOR: Record<Status, ChipStatusColor> = {
  CREATED: 'default',
  IN_PROGRESS: 'info',
  DONE_ACCEPTED: 'success',
  DONE_REJECTED: 'error',
};
export const STATUS_TO_LABEL: Record<Status, React.ReactElement> = {
  CREATED: Translate('Зарегистрировано'),
  IN_PROGRESS: Translate('В обработке'),
  DONE_ACCEPTED: Translate('Выполнено'),
  DONE_REJECTED: Translate('Отклонено'),
};
const STATUS_TO_ICON: Record<Status, React.ReactElement> = {
  CREATED: <Icons.ClipboardText />,
  IN_PROGRESS: <Icons.Timer className={css['timer']} />,
  DONE_ACCEPTED: <Icons.ClipboardTick />,
  DONE_REJECTED: <Icons.ClipboardClose />,
};

export const ChipStatusTask = React.memo<ChipStatusTaskProps>(({ status }) => (
  <ChipStatus
    color={STATUS_TO_COLOR[status]}
    icon={STATUS_TO_ICON[status]}
    label={STATUS_TO_LABEL[status]}
  />
));
