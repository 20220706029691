import { put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import {
  setTrustedParticipantsByInn,
  setTrustedParticipantsByName,
  setTrustedParticipants,
  setList,
} from '../actions/trusted-participants';
import { routes } from '../../routes';
import { EimzoService } from '../../lib/Eimzo';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';

export function* loadTrustedParticipantsByInnSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.innSuggest(payload, ['ASSOCIATE']);
    yield put(setTrustedParticipantsByInn([...json]));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* loadTrustedParticipantsByNameSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.nameSuggest(payload);
    yield put(setTrustedParticipantsByName([...json]));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* loadTrustedParticipantsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.product.getTrustedParticipants(
      payload.id,
      payload.query
    );
    yield put(setTrustedParticipants([...json]));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* updateTrustedParticipantSaga({ payload }) {
  try {
    const { id, grantedProducts, revokedProducts, onFinish, certificate } =
      payload;
    const key = yield EimzoService.loadKey(certificate);
    let resultGranted = null;
    if (grantedProducts?.length) {
      const associates = grantedProducts;
      const sign = yield EimzoService.sign(
        key.keyId,
        JSON.stringify(associates)
      );
      const withTimestamp = yield EimzoService.getTimestampSign(sign.pkcs7_64);
      if (!withTimestamp) {
        throw new Error('Timestamp sign error');
      }
      const signature = withTimestamp?.pkcs7b64;
      yield put(setIsLoading(true));
      resultGranted = yield API.rest.product.grantAccess(id, {
        signature,
        associates,
      });
    }
    let resultRevoked = null;
    if (revokedProducts?.length) {
      const associates = revokedProducts;
      const sign = yield EimzoService.sign(
        key.keyId,
        JSON.stringify(associates)
      );
      const withTimestamp = yield EimzoService.getTimestampSign(sign.pkcs7_64);
      if (!withTimestamp) {
        throw new Error('Timestamp sign error');
      }
      const signature = withTimestamp?.pkcs7b64;
      yield put(setIsLoading(true));
      resultRevoked = yield API.rest.product.revokeAccess(id, {
        signature,
        associates,
      });
    }
    if (resultGranted || resultRevoked) {
      onFinish();
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadTrustedParticipantListSaga({ payload }) {
  try {
    const { filter, pagination, sorting } = payload;
    const id = yield select((state) => state.products.item?.id);
    const query = {
      ...filter,
      ...pagination,
      ...sorting,
    };
    query.suitableAssociates = false;
    if (id) {
      const json = yield API.rest.product.getTrustedParticipants(id, query);
      yield put(setList([...json]));
    }
  } catch (err) {
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* deleteTrustedParticipantSaga({ payload }) {
  try {
    const { id, onFinish, certificate } = payload;
    const productId = yield select((state) => state.products.item?.id);
    const key = yield EimzoService.loadKey(certificate);
    let resultRevoked = null;
    const associates = [id];
    const sign = yield EimzoService.sign(key.keyId, JSON.stringify(associates));
    const withTimestamp = yield EimzoService.getTimestampSign(sign.pkcs7_64);
    if (!withTimestamp) {
      throw new Error('Timestamp sign error');
    }
    const signature = withTimestamp?.pkcs7b64;
    yield put(setIsLoading(true));
    resultRevoked = yield API.rest.product.revokeAccess(productId, {
      signature,
      associates,
    });
    if (resultRevoked) {
      onFinish();
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}
