import { types, Types } from '../types';
import { PAGINATION_DEFAULT } from './constants';
import { Name, PageState } from './index';

export type FiduciaryParticipantsActionType = Types[keyof Pick<
  Types,
  | 'SET_FIDUCIARY_PARTICIPANTS'
  | 'FIDUCIARY_PARTICIPANTS_GET_LIST'
  | 'FIDUCIARY_PARTICIPANTS_SET_LIST'
  | 'FIDUCIARY_PARTICIPANT_GET_ITEM'
  | 'FIDUCIARY_PARTICIPANT_SET_ITEM'
  | 'CLEAR_FIDUCIARY_PARTICIPANTS'
  | 'SET_POSSIBLE_TRUSTED_PARTICIPANTS'
  | 'GET_POSSIBLE_TRUSTED_PARTICIPANTS'
  | 'FIDUCIARY_PARTICIPANTS_SET_PRODUCTS'
  | 'FIDUCIARY_PARTICIPANTS_SET_PRODUCTS_FOR_CREATE'
  | 'FIDUCIARY_PARTICIPANT_DELETE'
  | 'UPDATE_FIDUCIARY_PARTICIPANT'
  | 'CREATE_FIDUCIARY_PARTICIPANT'
  | 'FIDUCIARY_PARTICIPANTS_SET_STATE_PRODUCTS_FOR_CREATE'
  | 'FIDUCIARY_PARTICIPANTS_SET_TRUSTED_PARTICIPANT'
  | 'FIDUCIARY_PARTICIPANTS_GET_PRODUCTS_FOR_CREATE'
  | 'FIDUCIARY_PARTICIPANTS_GET_PRODUCTS'
>];

export type ProductStatus = 'PUBLISHED' | 'BLOCKED';

export type FiduciaryParticipantData = {
  id: string;
  gtin: {
    value: string;
    attribute_name: Name;
  };
  tnved: {
    value: string;
    attribute_name: Name;
  };
  product_name: {
    value: Name;
    attribute_name: Name;
  };
  pg: {
    name: Name;
    value: string;
  };
  product_category: {
    name: Name;
    value: string;
    attribute_name: Name;
  };
  languages_complete: {
    value: Name;
    attribute_name: Name;
  };
  package_type: {
    name: Name;
    value: string;
    attribute_name: Name;
  };
  updated_at: {
    value: number;
    attribute_name: Name;
  };
  accessible: boolean;
};

const initialState = {
  possibleTrustedParticipants: [],
  products: {
    list: [],
    isLastPage: false,
    pagination: PAGINATION_DEFAULT,
    filter: undefined,
    sorting: {},
    id: undefined,
  },
  productsForCreate: {
    list: [],
    isLastPage: false,
    pagination: PAGINATION_DEFAULT,
    filter: undefined,
    sorting: {},
    trustedParticipant: null,
  },
  participants: {
    list: [],
    filter: undefined,
    sorting: {},
    isLastPage: false,
    pagination: PAGINATION_DEFAULT,
  },
  item: undefined,
};

export type FiduciaryParticipantsState = PageState<FiduciaryParticipantData>;

type FiduciaryParticipantsAction = {
  type: FiduciaryParticipantsActionType;
  payload: unknown;
};

export const reducer = (
  state: FiduciaryParticipantsState = initialState,
  action: FiduciaryParticipantsAction
): FiduciaryParticipantsState => {
  switch (action.type) {
    case types.FIDUCIARY_PARTICIPANTS_SET_LIST: {
      const data = action.payload as FiduciaryParticipantData[];
      return {
        ...state,
        participants: {
          ...state.participants,
          isLastPage: data.length < state.participants.pagination.limit,
          list: [...state.participants.list, ...data],
        },
      };
    }
    case types.CLEAR_FIDUCIARY_PARTICIPANTS_STATE: {
      return {
        ...state,
        participants: initialState.participants,
      };
    }
    case types.SET_POSSIBLE_TRUSTED_PARTICIPANTS:
      return {
        ...state,
        possibleTrustedParticipants: action.payload,
      };
    case types.FIDUCIARY_PARTICIPANTS_SET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          list: [...state.products.list, ...action.payload],
          isLastPage: action.payload.length < state.products.pagination.limit,
        },
      };
    case types.FIDUCIARY_PARTICIPANTS_SET_PRODUCTS_STATE:
      return {
        ...state,
        products: {
          ...state.products,
          ...action.payload,
        },
      };
    case types.FIDUCIARY_PARTICIPANTS_CLEAR_PRODUCTS_STATE:
      return {
        ...state,
        products: initialState.products,
      };
    case types.FIDUCIARY_PARTICIPANTS_SET_PRODUCTS_FOR_CREATE:
      return {
        ...state,
        productsForCreate: {
          ...state.productsForCreate,
          list: [...state.productsForCreate.list, ...action.payload],
          isLastPage:
            action.payload.length < state.productsForCreate.pagination.limit,
        },
      };
    case types.FIDUCIARY_PARTICIPANTS_SET_STATE_PRODUCTS_FOR_CREATE:
      return {
        ...state,
        productsForCreate: {
          ...state.productsForCreate,
          filter: action.payload.filter,
          pagination: action.payload.pagination,
          sorting: action.payload.sorting,
        },
      };
    case types.FIDUCIARY_PARTICIPANTS_CLEAR_STATE_PRODUCTS_FOR_CREATE:
      return {
        ...state,
        productsForCreate: {
          ...initialState.productsForCreate,
          trustedParticipant: state.productsForCreate.trustedParticipant,
        },
      };
    case types.FIDUCIARY_PARTICIPANTS_SET_TRUSTED_PARTICIPANT:
      return {
        ...state,
        productsForCreate: {
          ...state.productsForCreate,
          trustedParticipant: action.payload,
        },
      };
    case types.FIDUCIARY_PARTICIPANT_SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case types.SET_FIDUCIARY_PARTICIPANTS:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_FIDUCIARY_PARTICIPANTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
