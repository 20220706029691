import React from 'react';

export const Logo = () => (
  <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#F6F42E" height="24" rx="4" width="24" />
    <path
      clipRule="evenodd"
      d="M6 7a1 1 0 0 1 1-1h2.5V4H7a3 3 0 0 0-3 3v2.5h2V7zm8.5-1H17a1 1 0 0 1 1 1v2.5h2V7a3 3 0 0 0-3-3h-2.5v2zM6 17v-2.5H4V17a3 3 0 0 0 3 3h2.5v-2H7a1 1 0 0 1-1-1zm12-2.5V17a1 1 0 0 1-1 1h-2.5v2H17a3 3 0 0 0 3-3v-2.5h-2zm-6.615 1.196 5.333-5.5-1.436-1.392-4.584 4.727-1.95-2.195-1.495 1.328 2.666 3 .716.805.75-.773z"
      fill="#63666A"
      fillRule="evenodd"
    />
  </svg>
);
