import { put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { setIsLoading } from '../actions/common';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';
import { routes } from '../../routes';
import { API } from '../../api';
import {
  setFiduciaryParticipantsProducts,
  setFiduciaryParticipantsProductsForCreate,
  setItem,
  setList,
  setPossibleTrustedParticipants,
} from '../actions/fiduciary-participants';
import { EimzoService } from '../../lib/Eimzo';

export function* loadFiduciaryParticipantSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.fiduciaryParticipants.getItem(payload);
    yield put(setItem(json));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* loadFiduciaryParticipantsListSaga({ payload }) {
  try {
    const { filter, pagination, sorting } = payload;
    const associateInn = yield select((state) => state.participants.item?.inn);
    const query = {
      ...filter,
      ...pagination,
      ...sorting,
    };
    if (associateInn) {
      yield put(setIsLoading(true));
      const json = yield API.rest.fiduciaryParticipants.getList(
        associateInn,
        query
      );
      yield put(setList([...json]));
      yield put(setIsLoading(false));
    }
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* loadFiduciaryParticipantProductsSaga({ payload }) {
  try {
    const idFromStore = yield select(
      (state) => state.fiduciaryParticipants.item?.id
    );
    const language = yield select((state) => state.settings.language);
    const { id, pagination, filter, sorting, ...query } = payload;
    yield put(setIsLoading(true));
    const json = yield API.rest.fiduciaryParticipants.getProducts(
      id || idFromStore || location.pathname.split('/').reverse()[0],
      {
        ...pagination,
        ...filter,
        ...sorting,
        ...query,
        language,
      }
    );
    yield put(setFiduciaryParticipantsProducts(json));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    // yield put(
    //   push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    // );
    yield Toast.showError({ errors: err });
  }
}

export function* loadFiduciaryParticipantProductsForCreateSaga({ payload }) {
  try {
    const { trustedParticipant } = yield select(
      (state) => state.fiduciaryParticipants.productsForCreate
    );
    const language = yield select((state) => state.settings.language);
    const isEditing = location.pathname.split('/').reverse()[0] !== 'create';

    const { id, inn } = trustedParticipant;
    const { pagination, filter, sorting, ...query } = payload;
    const preparedQuery = {
      ...pagination,
      ...query,
      ...filter,
      ...sorting,
      ...(id ? { associate_id: id } : { associate_inn: inn }),
      language,
    };
    if (!isEditing) {
      preparedQuery.accessibility = 'POTENTIAL';
    }

    yield put(setIsLoading(true));
    const json = yield API.rest.fiduciaryParticipants.getProductsForCreate(
      preparedQuery
    );
    yield put(setFiduciaryParticipantsProductsForCreate(json));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    // yield put(
    //   push(routes.serverError('', { code: err?.response?.data?.code || 500 }))
    // );
    yield Toast.showError({ errors: err });
  }
}

export function* loadPossibleTrustedParticipantsListSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.newAssociates({
      type: 'INN',
      q: payload,
    });
    yield put(setPossibleTrustedParticipants([...json]));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* createFiduciaryParticipantSaga({ payload }) {
  try {
    const { body, onFinish, certificate } = payload;
    let signature = '';
    if (body) {
      const key = yield EimzoService.loadKey(certificate);
      const sign = yield EimzoService.sign(
        key.keyId,
        JSON.stringify(body.associateData)
      );
      const withTimestamp = yield EimzoService.getTimestampSign(sign.pkcs7_64);
      if (!withTimestamp) {
        throw new Error('Timestamp sign error');
      }
      signature = withTimestamp?.pkcs7b64;
    }
    yield put(setIsLoading(true));
    const json = yield API.rest.fiduciaryParticipants.create({
      ...body,
      signature,
    });
    if (json) {
      onFinish();
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* updateFiduciaryParticipantSaga({ payload }) {
  try {
    const { id, grantedProducts, revokedProducts, onFinish, certificate } =
      payload;
    const key = yield EimzoService.loadKey(certificate);
    let resultGranted = null;
    if (grantedProducts?.length) {
      const sign = yield EimzoService.sign(
        key.keyId,
        JSON.stringify(grantedProducts)
      );
      const withTimestamp = yield EimzoService.getTimestampSign(sign.pkcs7_64);
      if (!withTimestamp) {
        throw new Error('Timestamp sign error');
      }
      const signature = withTimestamp?.pkcs7b64;
      yield put(setIsLoading(true));
      resultGranted = yield API.rest.fiduciaryParticipants.grantAccess(id, {
        signature,
        grantedProducts,
      });
    }
    let resultRevoked = null;
    if (revokedProducts?.length) {
      const sign = yield EimzoService.sign(
        key.keyId,
        JSON.stringify(revokedProducts)
      );
      const withTimestamp = yield EimzoService.getTimestampSign(sign.pkcs7_64);
      if (!withTimestamp) {
        throw new Error('Timestamp sign error');
      }
      const signature = withTimestamp?.pkcs7b64;
      yield put(setIsLoading(true));
      resultRevoked = yield API.rest.fiduciaryParticipants.revokeAccess(id, {
        signature,
        revokedProducts,
      });
    }
    if (resultGranted || resultRevoked) {
      onFinish();
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* deleteFiduciaryParticipantSaga({ payload }) {
  try {
    const { id, onFinish, certificate } = payload;
    let signature = '';
    if (payload) {
      const key = yield EimzoService.loadKey(certificate);
      const sign = yield EimzoService.sign(key.keyId, JSON.stringify(id));
      const withTimestamp = yield EimzoService.getTimestampSign(sign.pkcs7_64);
      if (!withTimestamp) {
        throw new Error('Timestamp sign error');
      }
      signature = withTimestamp?.pkcs7b64;
    }
    yield put(setIsLoading(true));
    const json = yield API.rest.fiduciaryParticipants.remove(id, {
      signature,
    });
    if (json) {
      onFinish();
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}
