import React from 'react';

import { useSelector } from 'react-redux';

import { Tabs as CrptTabs } from '@crpt/material';

import { useOverriddenTheme } from '../../../hooks/theme';

export const Tabs = ({ children, value, onChange }) => {
  const { countryCode } = useSelector((state) => state.settings.common);
  const theme = useOverriddenTheme(countryCode);

  return (
    <CrptTabs theme={theme} value={value} onChange={onChange}>
      {children}
    </CrptTabs>
  );
};
