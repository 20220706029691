import { createTheme } from '@mui/material';

import { defaultTheme } from '@crpt/material';

import { palette as amThemePalette } from '../components/themes/am-theme';
import { palette as uzThemePalette } from '../components/themes/uz-theme';
import { palette as kzThemePalette } from '../components/themes/kz-theme';
import { getComponents } from '../components/themes/common';

const amTheme = createTheme(defaultTheme, {
  palette: {
    ...amThemePalette,
  },
  components: {
    ...getComponents(amThemePalette),
  },
});

const kzTheme = createTheme(defaultTheme, {
  palette: {
    ...kzThemePalette,
  },
  components: {
    ...getComponents(kzThemePalette),
  },
});

export const uzTheme = createTheme(defaultTheme, {
  palette: {
    ...uzThemePalette,
  },
  components: {
    ...getComponents(uzThemePalette),
  },
});

export const useOverriddenTheme = (countryCode) => {
  switch (countryCode) {
    case 'HY':
    case 'AM': {
      return amTheme;
    }
    case 'UZ': {
      return uzTheme;
    }
    case 'KZ': {
      return kzTheme;
    }
    default:
      return defaultTheme;
  }
};
