import { AnyAction } from 'redux';

import { types } from '../types';

import { CountryCode, Language } from '.';

export type AssignedTasksErrorsData = {
  code: number;
  message: string;
  taskIds: number[];
};

export type SettingsState = {
  common: {
    countryCode: CountryCode;
  };
  isLoading: boolean;
  language: Language;
  defaultLanguage: Language;
  tasksWithErrors: AssignedTasksErrorsData[];
  tab: string;
  rejectedLanguages: Language[];
};

const initialState: SettingsState = {
  common: {
    countryCode: (window.env.COUNTRY_CODE || 'RU') as CountryCode,
  },
  isLoading: true,
  language: (localStorage.getItem('language') ||
    window.env.DEFAULT_LANGUAGE ||
    'ru') as Language,
  defaultLanguage: window.env.DEFAULT_LANGUAGE,
  tasksWithErrors: [],
  tab: '',
  rejectedLanguages: [],
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.SET_COMMON_SETTINGS:
      return {
        ...state,
        common: action.payload,
      };
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case types.SET_ASSIGNED_TASK_WITH_ERROR:
      return {
        ...state,
        tasksWithErrors: [...action.payload],
      };
    case types.SET_TASKS_TABS:
      return {
        ...state,
        tab: action.payload,
      };
    case types.SET_REJECTED_LANGS:
      return {
        ...state,
        rejectedLanguages: action.payload,
      };
    default:
      return state;
  }
};
