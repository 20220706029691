import React from 'react';

import { Box, defaultTheme, styled } from '@crpt/material';

import { FilterableColumnInstance } from './TableFilter.types';

interface FilterEditorProps {
  error?: string;
  column?: FilterableColumnInstance<Record<string, any>>;
  title?: React.ReactNode;
  children: React.ReactNode;
}

export function FilterEditor(props: FilterEditorProps) {
  const { column, error, children, title } = props;
  return (
    <FilterEditorRoot className="FilterEditor">
      <Box className="FilterEditor-Title">
        {title ||
          (column?.Header ? column?.render('Header') : column?.id || '')}
      </Box>
      {error && <Box className="FilterEditor-Error">{error}</Box>}
      {children}
    </FilterEditorRoot>
  );
}

const FilterEditorRoot = styled(Box)(({ theme }) => ({
  marginBottom: 24,
  ':last-of-type': {
    marginBottom: 0,
  },
  '.FilterEditor-Title': {
    ...theme.typography.h5,
    paddingBottom: 8,
    color: theme.palette.grey['100'],
  },
  '.FilterEditor-Error': {
    ...theme.typography.p3,
    color: theme.palette.red['70'],
    paddingBottom: 12,
  },
}));

FilterEditorRoot.defaultProps = {
  theme: defaultTheme,
};
