import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@crpt-ui/core';

import { routes } from '../../routes';

import styles from './index.module.scss';

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.line} />
      <div className={styles.stroke}>
        <h2>404</h2>
        <h3>{t('Страница не найдена')}</h3>
      </div>
      <p>{t('К сожалению, запрошенной вами страницы не существует.')}</p>
      <Link
        className={styles.transfer}
        component={RouterLink}
        to={routes.products()}
      >
        {t('Перейти на главную')}
      </Link>
    </div>
  );
};
