import { types } from '../types';

export type CurrentUserState = {
  id: number;
  inn: number;
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_DATA:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
