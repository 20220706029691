import { lazy } from 'react';

/**
 *
 * Asynchronously loads the component for ProductGroupsPage
 *
 */
export const ProductGroupsPage = lazy(() =>
  import('./index').then((module) => ({ default: module.ProductGroupsPage }))
);
