import React, { useCallback, useRef, useState } from 'react';
import { Box, ButtonBase, ButtonBaseProps, Popover } from '@crpt/material';
import { DropDownMarker } from './components/DropDownMarker';

interface MenuItemProps extends Omit<ButtonBaseProps, 'children'> {
  label: string;
  icon?: React.ReactNode;
  selected?: boolean;
  dropdown?: boolean;
  children?: (closePopup: () => void) => React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}

export function MainMenuItem({
  label,
  icon,
  selected,
  dropdown,
  children,
  onClick,
  onClose,
  onOpen,
  ...buttonProps
}: MenuItemProps) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleMenuItemClick = useCallback(() => {
    const isOpen = !open;
    setOpen(isOpen);
    if (isOpen) {
      onOpen && onOpen();
    }
  }, [open]);
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, []);

  const className = ['AppMainMenu-Item'];
  open && className.push('--open');
  selected && className.push('--selected');

  return (
    <>
      <ButtonBase
        {...buttonProps}
        ref={anchorRef}
        className={className.join(' ')}
        onClick={dropdown ? handleMenuItemClick : onClick}
      >
        {icon ? <Box className="AppMainMenu-ItemIcon">{icon}</Box> : null}
        <Box className="AppMainMenu-ItemLabel">
          {label}
          {children && dropdown ? <DropDownMarker open={open} /> : null}
        </Box>
      </ButtonBase>
      {!dropdown ? null : (
        <Popover
          PaperProps={{
            style: {
              borderRadius: '0 0 8px 8px',
              boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.08)',
              minWidth: 282,
            },
          }}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {children ? children(handleClose) : null}
        </Popover>
      )}
    </>
  );
}

MainMenuItem.defaultProps = {
  dropdown: false,
};
