import React, { lazy, Suspense, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFoundPage } from '../../pages/errorPages/notFoundPage';
import { routes } from '../../routes';
import {
  getEnum,
  getProductGroupsEnum,
  getProductCategoriesEnum,
} from '../../store/actions/enums';
import { ServerErrorPage } from '../../pages/errorPages/serverErrorPage';
import { useApplicationConfig } from '../application-config/application-config';
import { Spinner } from '../spinner';
import { AuthRoute } from '../auth-route/auth-route';
import { ProductGroupsPage } from '../../pages/productGroups/Loadable';
import { ProductGroupDetails } from '../../pages/productGroupDetails/Loadable';

import { HeaderView } from './header';

import './index.scss';

const Participants = lazy(() =>
  import('../../pages/participants').then(({ Participants }) => ({
    default: Participants,
  }))
);

const Products = lazy(() =>
  import('../../pages/products').then((module) => ({
    default: module.Products,
  }))
);
const ParticipantView = lazy(() =>
  import('../../pages/participants/view/index.tsx').then(
    ({ ParticipantView }) => ({
      default: ParticipantView,
    })
  )
);

const CreateFiduciaryParticipant = lazy(() =>
  import('../../pages/participants/create-fiduciary-participant/index').then(
    ({ CreateFiduciaryParticipant }) => ({
      default: CreateFiduciaryParticipant,
    })
  )
);

const FiduciaryParticipantView = lazy(() =>
  import('../../pages/participants/view-fiduciary-participant/index').then(
    ({ FiduciaryParticipantView }) => ({
      default: FiduciaryParticipantView,
    })
  )
);

const Prefixes = lazy(() =>
  import('../../pages/prefixes').then(({ Prefixes }) => ({
    default: Prefixes,
  }))
);

const ProductForm = lazy(() =>
  import('../../pages/products/form').then(({ ProductForm }) => ({
    default: ProductForm,
  }))
);
const ProductView = lazy(() =>
  import('../../pages/products/view').then(({ ProductView }) => ({
    default: ProductView,
  }))
);
const Tasks = lazy(() =>
  import('../../pages/tasks').then(({ Tasks }) => ({
    default: Tasks,
  }))
);

export const Layout = () => {
  const { language: sortLanguage } = useSelector((state) => state.settings);
  const dsp = useDispatch();
  useEffect(() => {
    dsp(getEnum({ name: 'language' }));
    dsp(getEnum({ name: 'units' }));
    dsp(getEnum({ name: 'package_type', sortLanguage }));
    dsp(getEnum({ name: 'product_attribute_group', sortLanguage }));
    dsp(getEnum({ name: 'product_status', sortLanguage }));
    dsp(getEnum({ name: 'photo_angle', sortLanguage }));
    dsp(getProductGroupsEnum());
    dsp(getProductCategoriesEnum());
  }, [dsp, sortLanguage]);
  const accessRights = useApplicationConfig();
  return (
    <div className="layout">
      <div className="header">
        <HeaderView />
      </div>
      <div className="main">
        <div className="page">
          <Suspense fallback={<Spinner />}>
            <Switch>
              <AuthRoute
                exact
                component={Tasks}
                hasAccess={accessRights.tasks.canRead}
                path={routes.tasks()}
              />
              <AuthRoute
                exact
                component={ProductView}
                hasAccess={accessRights.tasks.canRead}
                path={routes.tasks(':id')}
              />
              <AuthRoute
                exact
                component={Products}
                hasAccess={accessRights.products.canRead}
                path={routes.products()}
              />
              <AuthRoute
                component={ProductForm}
                hasAccess={accessRights.products.canWrite}
                path={routes.products('create')}
              />
              <AuthRoute
                exact
                component={ProductView}
                hasAccess={accessRights.products.canRead}
                path={routes.products(':id')}
              />
              <AuthRoute
                component={ProductForm}
                hasAccess={
                  accessRights.products.canWrite ||
                  accessRights.products.canWriteNC
                }
                path={routes.products('edit/:id')}
              />
              <AuthRoute
                component={ProductForm}
                hasAccess={
                  accessRights.products.canWrite ||
                  accessRights.products.canWriteNC
                }
                path={routes.products('edit-published/:id')}
              />
              <AuthRoute
                exact
                component={Participants}
                hasAccess={accessRights.participants.canRead}
                path={routes.participants()}
              />
              <AuthRoute
                exact
                component={CreateFiduciaryParticipant}
                hasAccess={
                  accessRights.associate.canWrite ||
                  accessRights.associate.canCreate
                }
                path={routes.fiduciaryParticipant(':id')}
              />
              <AuthRoute
                exact
                component={FiduciaryParticipantView}
                hasAccess={accessRights.associate.canRead}
                path={routes.fiduciaryParticipantView(':id')}
              />
              <AuthRoute
                exact
                component={ParticipantView}
                hasAccess={accessRights.participants.canRead}
                path={routes.participants(':id')}
              />
              <AuthRoute
                component={Prefixes}
                hasAccess={accessRights.prefixes.canRead}
                path={routes.prefixes()}
              />
              <AuthRoute
                exact
                component={ProductGroupsPage}
                hasAccess={accessRights.productGroups.canRead}
                path={routes.productGroups()}
              />
              <AuthRoute
                exact
                component={ProductGroupDetails}
                hasAccess={accessRights.productGroups.canRead}
                path={routes.productGroups(':id')}
              />
              <Route
                exact
                component={ServerErrorPage}
                path={routes.serverError()}
              />
              <Route exact path="/">
                <Redirect to={routes.products()} />
              </Route>
              <Route component={NotFoundPage} path="*" />
            </Switch>
          </Suspense>
        </div>
      </div>
    </div>
  );
};
