/* eslint-disable */
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { default as createSagaMiddleware } from 'redux-saga';
import { createBrowserHistory } from 'history';
import { rootSaga } from './store/sagas';
import { createRootReducer } from './store/reducers';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const enhancers = [];
const middleware = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const store = createStore(createRootReducer(history), composedEnhancers);

sagaMiddleware.run(rootSaga);
