import { types, Types } from '../types';

import { PAGINATION_DEFAULT } from './constants';
import { PrefixData } from './prefixes';
import { ProductGroupData } from './productGroups';

import { Name, PageState } from './index';

export type ParticipantsActionType = Types[keyof Pick<
  Types,
  | 'SET_PARTICIPANTS'
  | 'PARTICIPANTS_GET_LIST'
  | 'PARTICIPANTS_SET_LIST'
  | 'PARTICIPANTS_GET_ITEM'
  | 'PARTICIPANTS_SET_ITEM'
  | 'CLEAR_PARTICIPANTS'
  | 'BLOCK_PARTICIPANTS'
  | 'UPDATE_PARTICIPANTS'
  | 'GET_PARTICIPANT_PRODUCT'
  | 'SET_PARTICIPANT_PRODUCT'
>];

export type ParticipantStatus = 'FULL_ACTIVE' | 'ACTIVE' | 'INACTIVE';
export type ParticipantStatusExt = 'ACTIVE' | 'CANCELLED' | 'SUSPENDED';
export type ParticipantSource = 'ISMPT' | 'GS1_UZ';

export type ParticipantData = {
  inn: string;
  name: Name;
  status: ParticipantStatus;
  gs1_resident: boolean;
  published: number;
  product_groups: number;
  prefixes: number;
  status_ext: ParticipantStatusExt;
};

export type ParticipantDetailedData = Pick<
  ParticipantData,
  'inn' | 'name' | 'status' | 'status_ext'
> & {
  product_group: ProductGroupData[];
  source: ParticipantSource;
  prefix: PrefixData[];
  gs1_active: boolean;
};

export type ParticipantsState = PageState<
  ParticipantData,
  ParticipantDetailedData
> & {
  products: any[];
};

const initialState: ParticipantsState = {
  list: [],
  products: [],
  item: undefined,
  filter: undefined,
  sorting: {},
  isLastPage: false,
  pagination: PAGINATION_DEFAULT,
};

type ParticipantsAction = {
  type: ParticipantsActionType;
  payload: unknown;
};

export const reducer = (
  state: ParticipantsState = initialState,
  action: ParticipantsAction
): ParticipantsState => {
  switch (action.type) {
    case types.PARTICIPANTS_SET_LIST: {
      const data = action.payload as ParticipantData[];
      return {
        ...state,
        isLastPage: data.length < state.pagination.limit,
        list: [...state.list, ...data],
      };
    }
    case types.PARTICIPANTS_SET_ITEM:
      return {
        ...state,
        item: action.payload as ParticipantDetailedData,
      };
    case types.SET_PARTICIPANTS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_PARTICIPANT_PRODUCT:
      return {
        ...state,
        products: action.payload,
      };
    case types.CLEAR_PARTICIPANTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
