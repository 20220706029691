import React from 'react';

import { buttonClasses } from '@mui/material';
import { useSelector } from 'react-redux';

import { Button as MuiButton, styled } from '@crpt/material';

import { useOverriddenTheme } from '../../../hooks/theme';

const ButtonStyled = styled(MuiButton)(({ theme }) => ({
  [`&.${buttonClasses.containedError}`]: {
    color: theme.palette.text.contrastText,
  },
  [`&.${buttonClasses.containedPrimary}`]: {
    color: theme.palette.text.contrastText,
  },
  [`&.${buttonClasses.containedSecondary}`]: {
    color: theme.palette.text.primary,
  },
  [`&.${buttonClasses.disabled}`]: {
    color: theme.palette.text.disabled,
  },
}));

export const Button = ({ children, variant, ...rest }) => {
  const { countryCode } = useSelector((state) => state.settings.common);
  const theme = useOverriddenTheme(countryCode);
  return (
    <ButtonStyled variant={variant} {...rest} theme={theme}>
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  variant: 'contained',
};

/**
 * @deprecated
 * use named import instead
 * `import {Button} from '...'`
 * */
const defaultExport = Button;

// eslint-disable-next-line import/no-default-export
export default defaultExport;
