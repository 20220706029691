import {
  Box,
  BoxProps,
  ButtonBase,
  ButtonBaseProps,
  defaultTheme,
  styled,
  useTheme,
  ThemeProvider,
} from '@crpt/material';
import React from 'react';
import { LogoIcon } from '../icons';

const MainMenuRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['70'],
  padding: '0 24px 0 24px',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  minHeight: 56,
  fontFamily: theme.typography.fontFamily,

  '.AppMainMenu': {
    '&-Logo': {
      flex: '0 0 auto',
      paddingRight: 24,
    },
    '&-Items': {
      fontFamily: 'inherit',
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 auto',
      gap: 16,
      // flexWrap: 'wrap',
    },
    '&-Space': {
      flex: '1 1 auto',
      marginLeft: -16,
    },
    '&-Item': {
      fontFamily: 'inherit',
      height: 56,
      padding: '0 8px',
      flex: '0 0 auto',
      gap: 12,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.grey['0'],
      cursor: 'pointer',
      transition: theme.transitions.create(['background', 'color']),
      ':hover': {
        color: theme.palette.yellow['100'],
      },
      '&.--selected': {
        color: theme.palette.yellow['40'],
      },
      '&.--open': {
        backgroundColor: theme.palette.grey['0'],
        color: theme.palette.grey['100'],
      }
    },
    '&-ItemIcon': {
      flex: '0 0 auto',
      display: 'flex',
      alignItems: 'center',
    },
    '&-ItemLabel': {
      fontFamily: 'inherit',
      flex: '0 0 auto',
      lineHeight: 20 / 14,
      fontSize: 14,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      ':-webkit-any-link': {
        textDecorationThickness: 'none',
      },
    },
    '&-Panel': {
      fontFamily: 'inherit',
      height: 56,
      gap: 12,
      color: theme.palette.grey['0'],
      flex: '0 0 auto',
      width: '',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

MainMenuRoot.defaultProps = {
  theme: defaultTheme,
};

interface MainMenuProps extends BoxProps {
  LogoProps?: ButtonBaseProps;
}

export function MainMenu({ LogoProps, children, ...boxProps }: MainMenuProps) {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <MainMenuRoot {...boxProps}>
        <Box className="AppMainMenu-Logo">
          <ButtonBase {...LogoProps}>
            <LogoIcon />
          </ButtonBase>
        </Box>
        <Box className="AppMainMenu-Items">{children}</Box>
      </MainMenuRoot>
    </ThemeProvider>
  );
}
