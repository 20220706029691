import { createAction } from '../../helpers/redux';
import { ParticipantsActionType } from '../reducers/participants';
import { types } from '../types';

const createParticipantsAction = createAction<ParticipantsActionType>;

export const set = createParticipantsAction(types.SET_PARTICIPANTS);
export const getList = createParticipantsAction(types.PARTICIPANTS_GET_LIST);
export const setList = createParticipantsAction(types.PARTICIPANTS_SET_LIST);
export const getItem = createParticipantsAction(types.PARTICIPANTS_GET_ITEM);
export const setItem = createParticipantsAction(types.PARTICIPANTS_SET_ITEM);
export const clear = createParticipantsAction(types.CLEAR_PARTICIPANTS);
export const blockParticipant = createParticipantsAction(
  types.BLOCK_PARTICIPANTS
);
export const updateParticipant = createParticipantsAction(
  types.UPDATE_PARTICIPANTS
);
export const getParticipantProducts = createParticipantsAction(
  types.GET_PARTICIPANT_PRODUCT
);
export const setParticipantProducts = createParticipantsAction(
  types.SET_PARTICIPANT_PRODUCT
);
