import { createAction } from '../../helpers/redux';
import { types } from '../types';

export const getList = createAction(types.TRUSTED_PARTICIPANTS_GET_LIST);
export const setList = createAction(types.TRUSTED_PARTICIPANTS_SET_LIST);
export const set = createAction(types.TRUSTED_PARTICIPANTS_SET);
export const clear = createAction(types.TRUSTED_PARTICIPANTS_CLEAR);

export const getTrustedParticipantsByInn = createAction(
  types.GET_TRUSTED_PARTICIPANTS_BY_INN
);

export const setTrustedParticipantsByInn = createAction(
  types.SET_TRUSTED_PARTICIPANTS_BY_INN
);

export const getTrustedParticipantsByName = createAction(
  types.GET_TRUSTED_PARTICIPANTS_BY_NAME
);

export const setTrustedParticipantsByName = createAction(
  types.SET_TRUSTED_PARTICIPANTS_BY_NAME
);

export const getTrustedParticipants = createAction(
  types.GET_TRUSTED_PARTICIPANTS
);

export const setTrustedParticipants = createAction(
  types.SET_TRUSTED_PARTICIPANTS
);

export const updateTrustedParticipants = createAction(
  types.UPDATE_TRUSTED_PARTICIPANTS
);

export const deleteTrustedParticipants = createAction(
  types.DELETE_TRUSTED_PARTICIPANTS
);
