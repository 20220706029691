import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { setIsLoading } from '../actions/common';
import { setCreated, setList, clear } from '../actions/prefixes';
import { API } from '../../api';
import { routes } from '../../routes';
import { isIncorrectInputError } from '../../helpers';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';
import { Translate } from '../../components/translate';

export function* loadPrefixesListSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const query = {
      ...payload.filter,
      ...payload.pagination,
      ...payload.sorting,
    };
    const json = yield API.rest.prefixes.list(query);
    yield put(setList(json));
    yield put(setIsLoading(false));
  } catch (err) {
    if (isIncorrectInputError(err)) {
      yield put(clear());
    } else {
      yield put(
        push(
          routes.serverError('', { code: err?.response?.data?.status || 500 })
        )
      );
    }
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* blockPrefixesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.prefixes.block(payload.prefix, payload.query, payload.body);
    yield Toast.showSuccess({
      content: Translate('Префикс успешно заблокирован!'),
    });
    yield payload.onFinish && payload.onFinish();
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* deletePrefixesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.prefixes.remove(payload.prefix, payload.query, payload.body);
    yield Toast.showSuccess({ content: Translate('Префикс успешно удален!') });
    yield payload.onFinish && payload.onFinish();
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* createPrefixesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.prefixes.create(payload.prefix);
    yield put(setCreated(true));
    yield Toast.showSuccess({ content: Translate('Префикс успешно создан!') });
    yield payload.onFinish && payload.onFinish();
  } catch (err) {
    if (err.response.status === 400) {
      yield put(setCreated(false));
    }
    yield Toast.showError({ errors: err });
    yield put(setIsLoading(false));
  }
}
