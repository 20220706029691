import React, { ComponentType, memo, useMemo } from 'react';

import { AsyncLookupInputProps, AutocompleteValue } from '@crpt/material';

import { FilterEditor } from './FilterEditor';
import type {
  FilterRenderProps,
  FilterValidationHandler,
} from './TableFilter.types';
import { AsyncLookupInput } from './AsyncLookupInput';

type FilterValue<Value, Multiple> = AutocompleteValue<
  Value,
  Multiple,
  false,
  false
>;

export interface AsyncLookupFilterParams<
  Value,
  Option,
  Multiple extends boolean | undefined
> extends Pick<
    AsyncLookupInputProps<Value, Option, Multiple>,
    | 'searchOptions'
    | 'defaultOptions'
    | 'getOptionValue'
    | 'getOptionLabel'
    | 'fetchValue'
    | 'multiple'
    | 'label'
    | 'classes'
    | 'placeholder'
    | 'helperText'
  > {
  title?: React.ReactNode;
  OptionItemComponent?: ComponentType<Option>;
  validator?: FilterValidationHandler<FilterValue<Value, Multiple>>;
  minValueLength?: number;
  freeSolo?: boolean;
  validateInputValue?: (value: string) => boolean;
  onChangeValue?: (value: string | number | null) => void;
  watchExactValueInOptions?: boolean;
  disabled?: boolean;
  maxMultipleListLength?: number;
}

export function makeAsyncLookupFilter<
  Option extends Record<string, unknown>,
  Value extends string | number = string,
  Multiple extends boolean | undefined = false
>(filterParams: AsyncLookupFilterParams<Value, Option, Multiple>) {
  const {
    title,
    multiple,
    placeholder,
    label,
    helperText,
    validator,
    fetchValue,
    classes,
    searchOptions,
    defaultOptions,
    getOptionLabel,
    getOptionValue,
    OptionItemComponent,
    minValueLength,
    freeSolo,
    validateInputValue,
    onChangeValue,
    watchExactValueInOptions = false,
    disabled = false,
    maxMultipleListLength,
  } = filterParams;

  return memo((props: FilterRenderProps<FilterValue<Value, Multiple>>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;
    const { error } = useValidator(validator);
    const normalizedValue = useMemo<FilterValue<Value, Multiple>>(() => {
      if (filterValue === null || filterValue === '') {
        return (multiple ? [] : null) as FilterValue<Value, Multiple>;
      }
      if (
        watchExactValueInOptions &&
        !multiple &&
        Array.isArray(defaultOptions) &&
        defaultOptions.length &&
        !defaultOptions.find((el: { code: string }) => el.code === filterValue)
      ) {
        return '';
      }
      if (
        watchExactValueInOptions &&
        multiple &&
        Array.isArray(defaultOptions) &&
        Array.isArray(filterValue) &&
        defaultOptions.length
      ) {
        return filterValue.reduce((acc, item) => {
          const needed = defaultOptions?.find(
            (el: { code: string }) => el.code === item
          );
          if (needed?.code) acc.push(needed.code);
          return acc;
        }, []);
      }
      if (multiple) {
        return filterValue ? filterValue : [];
      }
      return filterValue;
    }, [filterValue, watchExactValueInOptions, defaultOptions]);

    return (
      <FilterEditor column={column} error={error} title={title}>
        <AsyncLookupInput
          classes={classes}
          defaultOptions={defaultOptions}
          error={Boolean(error)}
          fetchValue={fetchValue}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          freeSolo={Boolean(freeSolo)}
          helperText={helperText}
          label={label}
          minValueLength={minValueLength}
          multiple={multiple}
          name={column.id}
          placeholder={placeholder}
          renderOptionItem={(option) => {
            if (OptionItemComponent) {
              return <OptionItemComponent {...option} />;
            }
          }}
          searchOptions={searchOptions}
          value={normalizedValue}
          onBlur={executeValidation}
          validateInputValue={validateInputValue}
          onChange={(_, value) => {
            setFilterValue(value);
            onChangeValue && !Array.isArray(value) && onChangeValue(value);
          }}
          disabled={disabled}
          maxMultipleListLength={maxMultipleListLength}
        />
      </FilterEditor>
    );
  });
}
