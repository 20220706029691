import { createAction } from '../../helpers/redux';
import { FiduciaryParticipantsActionType } from '../reducers/fiduciary-participants';
import { types } from '../types';

const createParticipantsAction = createAction<FiduciaryParticipantsActionType>;

export const set = createParticipantsAction(types.SET_FIDUCIARY_PARTICIPANTS);
export const getList = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANTS_GET_LIST
);
export const getFiduciaryParticipantsProducts = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANTS_GET_PRODUCTS
);
export const setFiduciaryParticipantsProducts = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANTS_SET_PRODUCTS
);
export const setFiduciaryParticipantsProductsState = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANTS_SET_PRODUCTS_STATE
);
export const clearFiduciaryParticipantsProductsState = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANTS_CLEAR_PRODUCTS_STATE
);
export const clearFiduciaryParticipantsState = createParticipantsAction(
  types.CLEAR_FIDUCIARY_PARTICIPANTS_STATE
);
export const getFiduciaryParticipantsProductsForCreate =
  createParticipantsAction(
    types.FIDUCIARY_PARTICIPANTS_GET_PRODUCTS_FOR_CREATE
  );
export const setFiduciaryParticipantsProductsForCreate =
  createParticipantsAction(
    types.FIDUCIARY_PARTICIPANTS_SET_PRODUCTS_FOR_CREATE
  );
export const setFiduciaryParticipantsProductsStateForCreate =
  createParticipantsAction(
    types.FIDUCIARY_PARTICIPANTS_SET_STATE_PRODUCTS_FOR_CREATE
  );
export const clearFiduciaryParticipantsProductsStateForCreate =
  createParticipantsAction(
    types.FIDUCIARY_PARTICIPANTS_CLEAR_STATE_PRODUCTS_FOR_CREATE
  );
export const setTrustedParticipant = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANTS_SET_TRUSTED_PARTICIPANT
);
export const setList = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANTS_SET_LIST
);
export const getPossibleTrustedParticipants = createParticipantsAction(
  types.GET_POSSIBLE_TRUSTED_PARTICIPANTS
);
export const setPossibleTrustedParticipants = createParticipantsAction(
  types.SET_POSSIBLE_TRUSTED_PARTICIPANTS
);
export const clear = createParticipantsAction(
  types.CLEAR_FIDUCIARY_PARTICIPANTS
);
export const getItem = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANT_GET_ITEM
);
export const setItem = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANT_SET_ITEM
);
export const deleteFiduciaryParticipant = createParticipantsAction(
  types.FIDUCIARY_PARTICIPANT_DELETE
);
export const createFiduciaryParticipant = createParticipantsAction(
  types.CREATE_FIDUCIARY_PARTICIPANT
);
export const updateFiduciaryParticipant = createParticipantsAction(
  types.UPDATE_FIDUCIARY_PARTICIPANT
);
