import React from 'react';

import { toast } from 'react-toastify';
import { get, isEmpty } from 'lodash';

import { generateGuid } from '../../../helpers';
import { Translate } from '../../translate';

const parseErrors = (payload) => {
  const err = get(payload, 'response.data');
  const commonErrors = [];
  if (err) {
    const { shortages, description, violations, unknown_attributes } = err;

    if (shortages && !isEmpty(shortages)) {
      const message = [description];
      const langs = Object.keys(shortages);
      langs.forEach((lang) => {
        const str = `${lang}: ${shortages[lang].join(', ')}`;
        message.push(str);
      });
      return [{ id: generateGuid(), name: message }];
    }

    if (unknown_attributes && unknown_attributes.length) {
      const message = [description, 'unknown attributes:'];
      const attr = unknown_attributes.join(', ');
      message.push(attr);
      return [{ id: generateGuid(), name: message }];
    }

    if (violations && violations.length) {
      const errors = violations.map((el) => el.message);
      const message = [description, ...errors];
      return [{ id: generateGuid(), name: message }];
    }
    if (Array.isArray(err)) {
      return [{ id: generateGuid(), name: err.message }];
    }
    commonErrors.push({
      id: generateGuid(),
      name: description || Translate('Произошла ошибка'),
    });
  } else {
    commonErrors.push({
      id: generateGuid(),
      name: Translate('Произошла ошибка'),
    });
  }
  return [...commonErrors];
};

const getErrorsList = (errors) => {
  return parseErrors(errors).map((el) => (
    <span key={el.id}>
      {Array.isArray(el?.name) ? <span>{el.name.join('. ')}</span> : el.name}
    </span>
  ));
};

export class Toast {
  static showError({ content, errors }) {
    return toast(
      <div className="Toast-content">
        {errors ? getErrorsList(errors) : content}
      </div>,
      {
        className: 'error-toast-container',
        closeOnClick: true,
        autoClose: 5 * 1000,
        hideProgressBar: true,
        closeButton: true,
      }
    );
  }

  static showSuccess({ content, autoCloseDisabled }) {
    return toast(<div className="Toast-content">{content}</div>, {
      className: 'success-toast-container',
      closeOnClick: true,
      autoClose: autoCloseDisabled ? false : 5 * 1000,
      hideProgressBar: true,
      closeButton: true,
    });
  }
}
