import React from 'react';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

export const ToastWrapper = () => {
  return (
    <ToastContainer
      autoClose={false}
      closeOnClick={false}
      position={toast.POSITION.BOTTOM_LEFT}
    />
  );
};
