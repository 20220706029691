import { Types, types } from '../types';

import { PAGINATION_DEFAULT } from './constants';

import { Name, PageState, PageQueryParams } from '.';

type PrefixStatus = 'ALLOWED' | 'FORBIDDEN';

export type PrefixData = {
  inn: string;
  name: Name;
  code: number;
  status: PrefixStatus;
  description: string;
};

export type PrefixesState = PageState<PrefixData> & {
  prefixCreated: null | unknown;
};

const initialState: PrefixesState = {
  list: [],
  filter: {},
  sorting: {},
  prefixCreated: null,
  isLastPage: false,
  pagination: PAGINATION_DEFAULT,
};

export type PrefixesActionType = Types[keyof Pick<
  Types,
  | 'PREFIXES_GET_LIST'
  | 'PREFIXES_GET_LIST_MY'
  | 'PREFIXES_SET_LIST'
  | 'PREFIX_CREATED'
  | 'BLOCK_PREFIXES'
  | 'DELETE_PREFIXES'
  | 'CREATE_PREFIXES'
  | 'SET_PREFIXES'
  | 'CLEAR_PREFIXES'
>];

type PrefixesAction = {
  type: PrefixesActionType;
  payload: unknown;
};

export const reducer = (
  state: PrefixesState = initialState,
  action: PrefixesAction
): PrefixesState => {
  switch (action.type) {
    case types.PREFIXES_SET_LIST: {
      const data = action.payload as PrefixData[];
      return {
        ...state,
        isLastPage: data.length < state.pagination.limit,
        list: [...state.list, ...data],
      };
    }
    case types.PREFIX_CREATED:
      return {
        ...state,
        prefixCreated: action.payload,
      };
    case types.SET_PREFIXES: {
      const { filter, pagination, sorting } =
        action.payload as PageQueryParams<PrefixesState>;
      return {
        ...state,
        filter,
        pagination,
        sorting,
      };
    }
    case types.CLEAR_PREFIXES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
