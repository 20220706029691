import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  MainMenu,
  MainMenuContainer,
  MainMenuItem,
  MainMenuSpace,
  ProfileIcon,
} from '@crpt/header';
import {
  Icons,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@crpt/material';

import { logout } from '../../../store/actions/auth';
import { getShortName } from '../../../utils/utils';
import { PageMenu } from '../menu';
import { Translate } from '../../translate';
import { useOverriddenTheme } from '../../../hooks/theme';
import { LogoShort } from '../../logo';
import { LanguageSwitcher } from '../../language-switcher/languageSwitcher';
import { useApplicationConfig } from '../../application-config/application-config';

const openElk = () => {
  window.open(`${window.env.REACT_APP_ELK_URL}/#/dashboard`, '_blank');
};

const openIcom = () => {
  window.open(`${window.env.REACT_APP_ICOM_URL}/#/orders`, '_blank');
};

export const HeaderView = React.memo(() => {
  const user = useSelector((state) => state?.currentUser);
  const { countryCode } = useSelector((state) => state.settings.common);
  const theme = useOverriddenTheme(countryCode);
  const { transitions } = useApplicationConfig();

  const userName = useMemo(
    () => (!isEmpty(user) ? getShortName(user.full_name) : ''),
    [user]
  );
  const dsp = useDispatch();

  const onLogout = useCallback(
    (close) => {
      close();
      dsp(logout());
    },
    [dsp]
  );

  return (
    <MainMenuContainer>
      <MainMenu theme={theme}>
        <LogoShort country={countryCode} />
        <div className={'selectedAppMenu'}>
          <MainMenuItem selected label={Translate('Реестр товаров')} />
        </div>
        <MainMenuItem
          label={Translate('Маркировка товаров')}
          onClick={openElk}
        />
        {transitions.icom && (
          <MainMenuItem label={Translate('Заказ кодов')} onClick={openIcom} />
        )}
        <MainMenuSpace />
        <MainMenuItem dropdown icon={<ProfileIcon />} label={userName}>
          {(close) => (
            <MenuList>
              <MenuItem onClick={() => onLogout(close)}>
                <ListItemIcon>
                  <Icons.IconPlaceholder_medium />
                </ListItemIcon>
                <ListItemText>{Translate('Выйти')}</ListItemText>
              </MenuItem>
            </MenuList>
          )}
        </MainMenuItem>
        <LanguageSwitcher />
      </MainMenu>
      <PageMenu />
    </MainMenuContainer>
  );
});
