import { all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types } from '../types';

import { downloadFileSaga, uploadFileSaga } from './common';
import {
  blockParticipantsSaga,
  getParticipantProductsSaga,
  loadParticipantsItemSaga,
  loadParticipantsListSaga,
  updateParticipantsSaga,
} from './participants';
import {
  blockPrefixesSaga,
  createPrefixesSaga,
  deletePrefixesSaga,
  loadPrefixesListSaga,
} from './prefixes';
import {
  loadCategoriesListSaga,
  loadEnumByNameSaga,
  loadNationalClassifierSaga,
  loadTnvedByCategorySaga,
  loadTnvedSaga,
  loadTypicalCommentsSaga,
  loadProductGroupsEnumSaga,
  loadProductCategoriesEnumSaga,
  loadModeratorsSaga,
} from './enums';
import {
  loadTemplateSaga,
  loadGoodsListSaga,
  removeDraftSaga,
  updateProductDraftSaga,
  getProductSampleSaga,
  moderateProductSaga,
  blockProductSaga,
  loadGtinListSaga,
  createProductDraftSaga,
  getProductCopyForEditSaga,
  loadCerts,
  uploadFilesSaga,
  getCommentsAfterModerationSaga,
  getProductCategoryList,
  getProductFirstStepTemplateSaga,
} from './products';
import {
  loadTrustedParticipantsByInnSaga,
  loadTrustedParticipantsByNameSaga,
  loadTrustedParticipantsSaga,
  updateTrustedParticipantSaga,
  loadTrustedParticipantListSaga,
  deleteTrustedParticipantSaga,
} from './trusted-participants';
import { checkAuthSaga, logoutSaga } from './auth';
import { loadProductGroupListSaga } from './productsGroupList';
import {
  addModerationCommentsSaga,
  changeTaskStatusSaga,
  deleteCommentSaga,
  getModerationCommentsSaga,
  loadTaskItemSaga,
  loadTaskListSaga,
  assignTask,
  getReport,
} from './tasks';
import {
  loadProductGroupItemSaga,
  loadAttributesSaga,
  loadCodesSaga,
  loadPackageTypesSaga,
  loadPGCategoriesSaga,
  loadCurrentCategory,
} from './productGroupDetails.ts';
import {
  createFiduciaryParticipantSaga,
  deleteFiduciaryParticipantSaga,
  loadFiduciaryParticipantProductsForCreateSaga,
  loadFiduciaryParticipantProductsSaga,
  loadFiduciaryParticipantSaga,
  loadFiduciaryParticipantsListSaga,
  loadPossibleTrustedParticipantsListSaga,
  updateFiduciaryParticipantSaga,
} from './fiduciary-participants';

export function* rootSaga() {
  yield all([
    takeLatest(types.LOGOUT, logoutSaga),
    takeLatest(types.DOWNLOAD_FILE, downloadFileSaga),
    takeLatest(types.UPLOAD_FILE, uploadFileSaga),
    takeLatest(types.PARTICIPANTS_GET_LIST, loadParticipantsListSaga),
    takeLatest(types.PARTICIPANTS_GET_ITEM, loadParticipantsItemSaga),
    takeLatest(types.BLOCK_PARTICIPANTS, blockParticipantsSaga),
    takeLatest(types.UPDATE_PARTICIPANTS, updateParticipantsSaga),
    takeLatest(types.PREFIXES_GET_LIST, loadPrefixesListSaga),
    takeLatest(types.PREFIXES_GET_LIST_MY, loadPrefixesListSaga),
    takeLatest(types.TNVED_GET_LIST, loadTnvedSaga),
    takeLatest(types.CATEGORIES_GET_LIST, loadCategoriesListSaga),
    takeLatest(types.TNVED_BY_CATEGORIES_GET_LIST, loadTnvedByCategorySaga),
    takeLatest(types.NATIONAL_GET_LIST, loadNationalClassifierSaga),
    takeLatest(types.TEMPLATE_GET, loadTemplateSaga),
    takeEvery(types.ENUM_GET_LIST, loadEnumByNameSaga),
    takeLatest(types.GOODS_GET_LIST, loadGoodsListSaga),
    takeLatest(types.GET_TYPICAL_COMMENTS, loadTypicalCommentsSaga),
    takeLatest(types.GET_PRODUCT_GROUPS_ENUM, loadProductGroupsEnumSaga),
    takeLatest(
      types.GET_PRODUCT_CATEGORIES_ENUM,
      loadProductCategoriesEnumSaga
    ),
    takeLatest(types.REMOVE_DRAFT, removeDraftSaga),
    takeLatest(types.CREATE_PRODUCT_DRAFT, createProductDraftSaga),
    takeLatest(types.UPDATE_PRODUCT_DRAFT, updateProductDraftSaga),
    takeLatest(types.GET_PRODUCT_SAMPLE, getProductSampleSaga),
    takeLatest(
      types.GET_PRODUCT_FIRST_STEP_TEMPLATE,
      getProductFirstStepTemplateSaga
    ),
    takeLatest(types.GET_PRODUCT_CATEGORY_LIST, getProductCategoryList),
    takeLatest(types.GET_COPY_FOR_EDIT, getProductCopyForEditSaga),
    takeLatest(types.CHECK_AUTH_DATA, checkAuthSaga),
    takeLatest(types.BLOCK_PRODUCT, blockProductSaga),
    takeLatest(types.GET_CODES, loadCodesSaga),
    takeLatest(types.GET_ATTRIBUTES, loadAttributesSaga),
    takeLatest(types.GET_PACKAGE_LEVELS, loadPackageTypesSaga),
    takeLatest(types.GET_PG_CATEGORIES, loadPGCategoriesSaga),
    takeLatest(types.GET_PG_CURRENT_CATEGORY, loadCurrentCategory),
    takeLatest(types.GET_PRODUCT_GROUPS_LIST, loadProductGroupListSaga),
    takeLatest(types.GET_PRODUCT_GROUPS_ITEM, loadProductGroupItemSaga),
    takeLatest(types.TO_MODERATE_PRODUCT, moderateProductSaga),
    takeLatest(types.GET_CERTS, loadCerts),
    takeLatest(types.BLOCK_PREFIXES, blockPrefixesSaga),
    takeLatest(types.DELETE_PREFIXES, deletePrefixesSaga),
    takeLatest(types.CREATE_PREFIXES, createPrefixesSaga),
    takeLatest(types.GET_GTINS, loadGtinListSaga),
    takeLatest(types.UPLOAD_FILES, uploadFilesSaga),
    takeLatest(types.GET_PARTICIPANT_PRODUCT, getParticipantProductsSaga),
    takeLatest(types.GET_TASKS, loadTaskListSaga),
    takeLatest(types.CHANGE_TASK_STATUS, changeTaskStatusSaga),
    takeLatest(types.ADD_COMMENTS, addModerationCommentsSaga),
    takeLatest(types.GET_COMMENTS, getModerationCommentsSaga),
    takeLatest(types.GET_TASK_ITEM, loadTaskItemSaga),
    takeLatest(types.DELETE_COMMENT, deleteCommentSaga),
    takeLatest(types.GET_MODERATION_COMMENTS, getCommentsAfterModerationSaga),
    takeLatest(types.ASSIGN_TASK, assignTask),
    takeLatest(types.GET_REPORT, getReport),
    takeLatest(
      types.FIDUCIARY_PARTICIPANT_GET_ITEM,
      loadFiduciaryParticipantSaga
    ),
    takeLatest(
      types.FIDUCIARY_PARTICIPANTS_GET_LIST,
      loadFiduciaryParticipantsListSaga
    ),
    takeLatest(
      types.FIDUCIARY_PARTICIPANTS_GET_PRODUCTS,
      loadFiduciaryParticipantProductsSaga
    ),
    takeLatest(
      types.FIDUCIARY_PARTICIPANTS_GET_PRODUCTS_FOR_CREATE,
      loadFiduciaryParticipantProductsForCreateSaga
    ),
    takeLatest(
      types.FIDUCIARY_PARTICIPANT_DELETE,
      deleteFiduciaryParticipantSaga
    ),
    takeLatest(
      types.GET_POSSIBLE_TRUSTED_PARTICIPANTS,
      loadPossibleTrustedParticipantsListSaga
    ),
    takeLatest(
      types.CREATE_FIDUCIARY_PARTICIPANT,
      createFiduciaryParticipantSaga
    ),
    takeLatest(
      types.UPDATE_FIDUCIARY_PARTICIPANT,
      updateFiduciaryParticipantSaga
    ),
    takeLatest(
      types.GET_TRUSTED_PARTICIPANTS_BY_INN,
      loadTrustedParticipantsByInnSaga
    ),
    takeLatest(
      types.GET_TRUSTED_PARTICIPANTS_BY_NAME,
      loadTrustedParticipantsByNameSaga
    ),
    takeLatest(types.GET_TRUSTED_PARTICIPANTS, loadTrustedParticipantsSaga),
    takeLatest(types.UPDATE_TRUSTED_PARTICIPANTS, updateTrustedParticipantSaga),
    takeLatest(
      types.TRUSTED_PARTICIPANTS_GET_LIST,
      loadTrustedParticipantListSaga
    ),
    takeLatest(types.DELETE_TRUSTED_PARTICIPANTS, deleteTrustedParticipantSaga),
    takeLatest(types.GET_MODERATORS_LIST, loadModeratorsSaga),
  ]);
}
