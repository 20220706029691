import { styled } from '@mui/material';

import Button, { buttonClasses } from './Button';

export const Tab = styled(Button)(({ theme }) => ({
  [`&, &:hover , &.${buttonClasses.selected}`]: {
    color: theme.palette.text.primary,
  },

  [`&:hover`]: {
    boxShadow: `0 -2px 0 0 ${theme.palette.primary.main} inset`,
  },

  [`&.${buttonClasses.selected}`]: {
    boxShadow: `0 -2px 0 0 ${theme.palette.text.primary} inset`,
  },
}));
