import React, { memo } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  autocompleteClasses,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams,
  BaseTextFieldProps as MuiBaseTextFieldProps,
  Paper as MuiPaper,
  StandardTextFieldProps,
} from '@mui/material';
import {
  TextField,
  MenuItem,
  Chip,
  Icons,
  defaultTheme,
  styled,
  useTheme,
} from '@crpt/material';

const AutocompleteTextField = styled(TextField)(({ theme }) => ({
  [`.${autocompleteClasses.endAdornment}`]: {
    right: 16,
  },
  [`.${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {
    padding: 16,
  },
  [`.${autocompleteClasses.clearIndicator}`]: {
    color: theme.palette.blue['70'],
    ':hover': {
      color: theme.palette.blue['100'],
      backgroundColor: 'transparent',
    },
  },
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  marginTop: '4px',
  borderRadius: '8px',
  [`.${autocompleteClasses.noOptions}`]: {
    ...theme.typography.p2,
    color: theme.palette.grey['60'],
  },
  [`.${autocompleteClasses.loading}`]: {
    ...theme.typography.p2,
    color: theme.palette.grey['60'],
  },
}));

Paper.defaultProps = {
  theme: defaultTheme,
};

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
      MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
      'renderInput'
    >,
    Pick<
      MuiBaseTextFieldProps,
      'label' | 'placeholder' | 'error' | 'required' | 'helperText' | 'name'
    > {
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  InputProps?: (
    props: AutocompleteRenderInputParams['InputProps']
  ) => StandardTextFieldProps['InputProps'] | undefined;
  renderOptionItem?: (option: T) => React.ReactNode;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const {
    name,
    label,
    placeholder,
    error,
    required,
    helperText,
    loading,
    loadingText,
    InputProps,
    renderOptionItem,
    ...autocompleteProps
  } = props;

  const theme = useTheme();

  return (
    <MuiAutocomplete
      PaperComponent={Paper}
      loading={loading}
      loadingText={loadingText}
      ListboxProps={{
        style: { maxHeight: theme.sizes?.maxDropdownListSize },
      }}
      renderInput={(params) => (
        <AutocompleteTextField
          {...params}
          InputProps={InputProps?.(params.InputProps)}
          error={error}
          helperText={helperText}
          label={label}
          name={name}
          placeholder={placeholder}
          required={required}
        />
      )}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderOption={({ className, ...restProps }, option, state) => (
        <MenuItem {...restProps} key={restProps.id} selected={state.selected}>
          {renderOptionItem?.(option) ?? props.getOptionLabel?.(option)}
        </MenuItem>
      )}
      renderTags={(values, getTagProps) => {
        return (
          Array.isArray(values) &&
          values.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              label={props.getOptionLabel?.(option) || option}
              size="medium"
            />
          ))
        );
      }}
      {...autocompleteProps}
    />
  );
};

Autocomplete.defaultProps = {
  loadingText: 'Загрузка...',
  noOptionsText: 'НЕТ СОВПАДЕНИЙ',
  clearText: 'Очистить',
  openText: 'Открыть',
  closeText: 'Закрыть',
  InputProps: (props: AutocompleteRenderInputParams['InputProps']) => props,
  getOptionLabel: (option: unknown) => option.label ?? option,
  popupIcon: <Icons.ArrowDown_small />,
  clearIcon: <Icons.Close_medium />,
  forcePopupIcon: false,
};

// eslint-disable-next-line import/no-default-export
export default memo(Autocomplete) as unknown as typeof Autocomplete;
