import { SvgIcon, SvgIconProps } from '@crpt/material';
import React from 'react';

export const ArrowDownIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    style={{ width: 16, height: 16 }}
    cursor="pointer"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 6H4L8 10L12 6Z" fill="#555D67" />
  </SvgIcon>
);