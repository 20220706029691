import {
  inputClasses,
  InputLabel as MuiInputLabel,
  inputLabelClasses,
  InputLabelProps,
} from '@mui/material';

import { defaultTheme, styled } from '@crpt/material';

export const InputLabel = styled(MuiInputLabel)<InputLabelProps>(
  ({ theme = defaultTheme }) => ({
    [`&.${inputLabelClasses.root} + .${inputClasses.root}`]: {
      margin: 0,
    },
    [`&.${inputLabelClasses.root} + .${inputClasses.root} > .${inputClasses.input}`]:
      {
        padding: '8px 35px 5px 14px ',
      },

    [`&.${inputLabelClasses.root}`]: {
      ...theme.typography.p2,
      zIndex: 1,
      color: theme.palette.grey['100'],
      transform: 'translate(16px, 16px) scale(1)',

      [`.${inputLabelClasses.asterisk}`]: {
        color: theme.palette.orange['70'],
      },

      [`&, &.${inputLabelClasses.focused}, .${inputLabelClasses.disabled}, &.${inputLabelClasses.disabled} .${inputLabelClasses.asterisk}`]:
        {
          color: theme.palette.grey['60'],
        },

      [`&.${inputLabelClasses.focused}, &.${inputLabelClasses.shrink}, &.${inputLabelClasses.filled}`]:
        {
          transform: 'translate(16px, 8px) scale(0.87)',
        },
    },
  })
);
