import { createAction } from '../../helpers/redux';
import { TasksActionType } from '../reducers/tasks';
import { types } from '../types';

const createTasksAction = createAction<TasksActionType>;

export const getList = createTasksAction(types.GET_TASKS);
export const setList = createTasksAction(types.SET_TASKS_LIST);
export const getItem = createTasksAction(types.GET_TASK_ITEM);
export const setItem = createTasksAction(types.SET_TASK_ITEM);
export const clearList = createTasksAction(types.CLEAR_TASKS_LIST);
export const set = createTasksAction(types.SET_TASKS);
export const clear = createTasksAction(types.CLEAR_TASKS);
export const changeTaskStatus = createTasksAction(types.CHANGE_TASK_STATUS);
export const setTaskStatusChanged = createTasksAction(
  types.SET_TASK_STATUS_CHANGED
);
export const addComments = createTasksAction(types.ADD_COMMENTS);
export const deleteComments = createTasksAction(types.DELETE_COMMENT);
export const getComments = createTasksAction(types.GET_COMMENTS);
export const setComments = createTasksAction(types.SET_COMMENTS);
export const assignTask = createTasksAction(types.ASSIGN_TASK);
export const getReport = createTasksAction(types.GET_REPORT);
export const setTasksWithErrors = createTasksAction(
  types.SET_ASSIGNED_TASK_WITH_ERROR
);
