import React, { memo } from 'react';

import { MenuItem, FormControl, Select } from '@crpt/material';
import {
  FilterEditor,
  FilterValidationHandler,
  FilterRenderProps,
} from '@crpt/table/TableFilter';

import { InputLabel } from '../input-label';

import styles from './index.module.scss';

export interface MultiSelectFilterOptions<T> {
  title?: React.ReactNode;
  helperText?: string;
  label?: string;
  placeholder?: string;
  validator?: FilterValidationHandler<T>;
  disableReset?: boolean;
  options: { label?: string; name?: string; value: T }[];
  resetText?: string;
}

export function makeMultiSelectFilter<T extends string | number>(
  filterOptions?: MultiSelectFilterOptions<T>
) {
  const {
    title,
    placeholder,
    validator,
    options = [],
    theme,
  } = filterOptions || {};

  return memo((props: FilterRenderProps<T>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;
    const { error } = useValidator(validator);

    return (
      <FilterEditor column={column} error={error} title={title}>
        <FormControl className={styles.select}>
          <InputLabel id={column.id}>{placeholder}</InputLabel>
          <Select
            multiple
            name={column.id}
            value={filterValue || []}
            onChange={(value) => {
              setFilterValue(value);
              executeValidation();
            }}
          >
            {options.map((option, index) => (
              <MenuItem theme={theme} key={index} value={option.value}>
                {option.label || option.name || option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FilterEditor>
    );
  });
}
