import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { API } from '../../api';
import { routes } from '../../routes';
import { setIsLoading } from '../actions/common';
import {
  setItem,
  setPackageLevels,
  setCodes,
  setAttributes,
  setCategories,
  setCurrentCategory,
} from '../actions/productGroupDetails';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';

export function* loadPackageTypesSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.productGroups.getPackageTypes(action.payload);
    yield put(setPackageLevels(json.values));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadProductGroupItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.productGroups.item(action.payload);
    yield put(setItem(json));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.status || 500 }))
    );
    yield Toast.showError({ errors: err });
  }
}

export function* loadCodesSaga(action) {
  try {
    // yield put(setIsLoading(true));
    const json = yield API.rest.productGroups.getCodes(action.payload);
    yield put(setCodes(json));
    // yield put(setIsLoading(false));
  } catch (err) {
    // yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadAttributesSaga(action) {
  try {
    // yield put(setIsLoading(true));
    const json = yield API.rest.productGroups.attributes(action.payload);
    yield put(setAttributes(json));
    // yield put(setIsLoading(false));
  } catch (err) {
    // yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadPGCategoriesSaga(action) {
  const { code, query } = action.payload;
  try {
    // yield put(setIsLoading(true));
    const json = yield API.rest.productGroups.getCategories({ code, query });
    yield put(setCategories(json));
    // yield put(setIsLoading(false));
  } catch (err) {
    // yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadCurrentCategory(action) {
  const { code } = action.payload;
  try {
    const json = yield API.rest.category.getCurrentCategory({ code });
    yield put(setCurrentCategory(json));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}
