import React from 'react';

import LogoAM from '../../assets/logos/logoAM.png';
import LogoSmallAM from '../../assets/logos/logoSmallAM.png';
import LogoDefault from '../../assets/logos/1.png';

import LogoUZ from '../../assets/logos/LogoUz.svg';
import './index.scss';

export const Logo = React.memo(({ country }) => {
  switch (country) {
    case 'HY':
      return <img alt="" src={LogoAM} />;
    case 'AM':
      return <img alt="" src={LogoAM} />;
    case 'KZ':
      return <img alt="" src={LogoDefault} />;
    case 'AZ':
      return <img alt="" src={LogoDefault} />;
    default:
      return <img alt="" src={LogoDefault} />;
  }
});

export const LogoShort = React.memo(({ country }) => {
  switch (country) {
    case 'AM':
    case 'HY':
      return (
        <div className="logoContainer">
          <img alt="" src={LogoSmallAM} />
        </div>
      );
    case 'UZ':
      return (
        <div className="logoContainer">
          <img alt="" src={LogoUZ} />
        </div>
      );
    default:
      return (
        <div className="logoContainer">
          <img alt="" src={LogoDefault} />
        </div>
      );
  }
});
