import { Types, types } from '../types';

import { PAGINATION_DEFAULT } from './constants';

import { Name, PageQueryParams, PageState } from '.';

export type ProductGroupStatusCode = 'DRAFT' | 'PUBLISHED' | 'BLOCKED';
export type ProductGroupModeration = 'AUTO' | 'MANUAL';
export type PackageType = 'TRADE_UNIT' | 'INNER_PACK' | 'ANY';

export type ProductGroupData = {
  code: string;
  id: number;
  name: Name;
  status: ProductGroupStatusCode;
  moderation: ProductGroupModeration;
  constraints: {
    package: PackageType[];
    allow_technical: boolean;
    allow_technical_date: null | string;
  };
};

export type ProductGroupsState = PageState<ProductGroupData>;

export const initialState: ProductGroupsState = {
  list: [],
  item: {},
  filter: {},
  sorting: {},
  isLastPage: false,
  pagination: PAGINATION_DEFAULT,
};

export type ProductGroupsActionType = Types[keyof Pick<
  Types,
  'SET_PRODUCT_GROUPS_LIST' | 'CLEAR_PRODUCT_GROUPS_LIST' | 'SET_PRODUCT_GROUPS'
>];

type ProductGroupsAction = {
  type: ProductGroupsActionType;
  payload: unknown;
};

export const reducer = (
  state: ProductGroupsState = initialState,
  action: ProductGroupsAction
) => {
  switch (action.type) {
    case types.SET_PRODUCT_GROUPS_LIST: {
      const data = action.payload as ProductGroupData[];
      return {
        ...state,
        isLastPage: data.length < state.pagination.limit,
        list: [...state.list, ...data],
      };
    }
    case types.CLEAR_PRODUCT_GROUPS_LIST:
      return {
        ...initialState,
      };
    case types.SET_PRODUCT_GROUPS: {
      const { filter, pagination, sorting } =
        action.payload as PageQueryParams<ProductGroupsState>;
      return {
        ...state,
        filter,
        pagination,
        sorting,
      };
    }
    default:
      return state;
  }
};
