import { omit } from 'lodash-es';
import { AnyAction } from 'redux';

import { IProductGroupDetailsState } from '../../pages/productGroupDetails/types';
import { types } from '../types';

export const initialState: IProductGroupDetailsState = {};

export const productGroupDetailsReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case types.SET_PRODUCT_GROUPS_ITEM:
      return { ...state, item: action.payload };
    case types.CLEAR_PRODUCT_GROUPS_ITEM:
      return { ...initialState };
    case types.SET_PACKAGE_LEVELS:
      return { ...state, packageLevels: action.payload };
    case types.SET_CODES:
      return { ...state, codes: action.payload };
    case types.SET_ATTRIBUTES:
      return { ...state, attributes: action.payload };
    case types.SET_PG_CATEGORIES:
      return { ...state, categories: action.payload };
    case types.SET_PACKAGE_TYPE:
      return { ...state, packageType: action.payload };
    case types.SET_PG_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: {
          ...action.payload,
          attributes: action.payload?.attributes?.map((item) =>
            omit(
              {
                ...item,
                ...item.attribute,
              },
              'attribute'
            )
          ),
        },
      };
    default:
      return state;
  }
};
